import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Header from '../Header/Header';
import styles from './LedgerReport.module.css';
import { ThemeProvider } from '@emotion/react';
import { Button, Grid, createTheme } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const LedgerReport  = () => {

    const columns2: GridColDef[] = [
      { field: 'client', headerName: 'Client', 
      width: 130 
      },
      { field: 'id', headerName: 'Bet',
       width: 100 
      },
      { field: 'date', headerName: 'Date', 
      width: 210 
      },
      { field: 'event', headerName: 'Event', 
      width: 120 
      },
      { field: 'object', headerName: 'Object', 
      width: 130 
      },
      { field: 'stake', headerName: 'Stake', 
      width: 110
      },
      { field: 'odds', headerName: 'Odds', 
      width: 100 
      },
      { field: 'payout', headerName: 'Payout', 
      width: 130 
      },
      { field: 'internetAccount', headerName: 'Internet Account', 
      width: 200 
      },
      { field: 'terminalShop', headerName: 'Terminal Shop', 
      width: 200 
      },
    ];

    const rows2 = [
        {
          client: "John Doe",
          id: 101,
          date: "2023-10-01T12:00:00Z",
          event: "Football",
          object: "Match Winner",
          stake: "100",
          odds: "1.5",
          payout: "150",
          internetAccount: "john_doe_123",
          terminalShop: "Shop A"
        },
        {
          client: "Jane Smith",
          id: 102,
          date: "2023-10-01T12:00:00Z",
          event: "Basketball",
          object: "Total Points",
          stake: "200",
          odds: "2.0",
          payout: "400",
          internetAccount: "jane_smith_456",
          terminalShop: "Shop B"
        },
        {
          client: "Mike Johnson",
          id: 103,
          date: "2023-10-01T12:00:00Z",
          event: "Tennis",
          object: "Match Winner",
          stake: "150",
          odds: "1.8",
          payout: "270",
          internetAccount: "mike_johnson_789",
          terminalShop: "Shop C"
        },
        {
          client: "Emily Clarke",
          id: 104,
          date: "2023-10-01T12:00:00Z",
          event: "Baseball",
          object: "Run Line",
          stake: "250",
          odds: "2.5",
          payout: "625",
          internetAccount: "emily_clarke_101",
          terminalShop: "Shop D"
        },
        {
          client: "Alex Turner",
          id: 105,
          date: "2023-10-01T12:00:00Z",
          event: "Hockey",
          object: "Over/Under",
          stake: "300",
          odds: "1.9",
          payout: "570",
          internetAccount: "alex_turner_202",
          terminalShop: "Shop E"
        },
        {
            client: "Mike Johnson",
            id: 103,
            date: "2023-10-01T12:00:00Z",
            event: "Tennis",
            object: "Match Winner",
            stake: "150",
            odds: "1.8",
            payout: "270",
            internetAccount: "mike_johnson_789",
            terminalShop: "Shop C"
          },
          {
            client: "Emily Clarke",
            id: 104,
            date: "2023-10-01T12:00:00Z",
            event: "Baseball",
            object: "Run Line",
            stake: "250",
            odds: "2.5",
            payout: "625",
            internetAccount: "emily_clarke_101",
            terminalShop: "Shop D"
          },
          {
            client: "Alex Turner",
            id: 105,
            date: "2023-10-01T12:00:00Z",
            event: "Hockey",
            object: "Over/Under",
            stake: "300",
            odds: "1.9",
            payout: "570",
            internetAccount: "alex_turner_202",
            terminalShop: "Shop E"
          }
      ];


    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
    });

  return (
    <>
    <Header/>
    <ThemeProvider theme={darkTheme}>
        <div className={styles.mainContainer}>
            <div className={styles.subHeader}>
                <h4>Ledger Report</h4>
            </div>
            <div className={styles.dateRangeContainer}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="From">
                      <DatePicker />
                    </DemoItem>
                  </DemoContainer>
                </Grid>
                <Grid item>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="To">
                      <DatePicker />
                    </DemoItem>
                  </DemoContainer>

                </Grid>
                <Button style={{margin:'2%',marginTop:'4.6%'}} variant="contained" color="primary" size="small"
              // onClick={() => handleCancelClick(params.id)}
              >
                Get Ledger
              </Button>
              <Button style={{margin:'2%',marginTop:'4.6%'}} variant="contained" color="primary" size="small"
              // onClick={() => handleCancelClick(params.id)}
              >
                Change Currency
              </Button>
              </Grid>
              </LocalizationProvider>

            </div>
            <div className={styles.parent}>
            <DataGrid rows={rows2} columns={columns2} getRowHeight={()=>(50)} initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
            </div>
        </div>
        </ThemeProvider>
        </>
  );
};

export default LedgerReport;

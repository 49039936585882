import React, { useState } from 'react'
import Header from '../Header/Header'
import styles from './DepositContainer.module.css';
import { Button, TextField, styled } from '@mui/material';
import { PhoneInput } from 'react-international-phone';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import 'react-international-phone/style.css';
import './DepositContainer.css';


function DepositContainer() {
    const [phone, setPhone] = useState('');
    const [punterID, setPunterID] = useState('');
    const [sender, setSender] = useState('');

    const blue = {
      100: '#DAECFF',
      200: '#b6daff',
      400: '#3399FF',
      500: '#007FFF',
      600: '#0072E5',
      900: '#003A75',
    };
  
    const grey = {
      50: '#F3F6F9',
      100: '#E5EAF2',
      200: '#DAE2ED',
      300: '#C7D0DD',
      400: '#B0B8C4',
      500: '#9DA8B7',
      600: '#6B7A90',
      700: '#434D5B',
      800: '#303740',
      900: '#1C2025',
    };

    const Textarea = styled(BaseTextareaAutosize)(
      ({ theme }) => `
      box-sizing: border-box;
      width: 320px;
      font-family: 'IBM Plex Sans', sans-serif;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      padding: 8px 12px;
      border-radius: 8px;
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
      border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
      box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
      &:hover {
        border-color: ${blue[400]};
      }
  
      &:focus {
        border-color: ${blue[400]};
        box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
      }
  
      // firefox
      &:focus-visible {
        outline: 0;
      }
    `,
    );

      const textFieldSx = {
        width: '28.4%',
        '& label': {
          color: 'white', // Default label color
        },
        '& label.Mui-focused': {
          color: 'white', // Label color when the input is focused
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', // Border color
          },
          '&:hover fieldset': {
            borderColor: 'white', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Border color when focused
          },
          '& input': {
            color: 'white', // Input text color
          },
        },
      };

  return (
    <>
        <Header/>
        <div className={styles.mainContainer}>
            <h3 className={styles.heading}>SEND SMS FROM SYSTEM</h3>
            <div className={styles.subHeader}>
              
              <PhoneInput defaultCountry="ua" value={phone} onChange={(phone) => setPhone(phone)}/>
              
              <TextField label="Punter ID" value={punterID} onChange={(e) => setPunterID(e.target.value)}
                  fullWidth margin="normal" sx={textFieldSx} 
              />
              <TextField label="Sender" value={sender} onChange={(e) => setSender(e.target.value)}
                  fullWidth margin="normal" sx={textFieldSx} 
              />
              <Textarea className={styles.textArea} placeholder="Message" minRows={6} sx={textFieldSx} aria-label="maximum height" />
              <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', marginTop:'2%', padding:'0.7% 2.5%', border:'1px solid #1a2035'}}>SEND SMS</Button>
            </div>
        </div>
    </>

  )
}

export default DepositContainer
import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { Card, CardContent, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';
import styles from './RightSidebar.module.css';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface RightSidebarProps {
    isOpen: boolean;
    onClose: () => void; 
  }

const RightSidebar: React.FC<RightSidebarProps> = ({ isOpen, onClose }) => {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: true,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

    const label = { inputProps: { 'aria-label': 'bet-checkbox' } };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 340 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <h3 style={{textAlign:'center', backgroundColor:'#1f283e', color:"white", margin:'0', padding:'7%'}}>BetSlip Container</h3>
      <List>
      <Card className={classNames(styles['bet-main-container'])} sx={{ width: "100%" }}>
            <CardContent>
                <div className={classNames(styles['bet-container'])}>
                    <div className={classNames(styles['bet-checkbox'])}>
                        <Checkbox 
                        // checked={getCheckedStatus(props.objectId)}
                            // onChange={event=>handleCheckboxChange(event,parseInt(props.groupId),props.eventId, props.objectId)} 
                            {...label} 
                        />
                    </div>
                    <div className={classNames(styles['bet-description'])}>
                        <p className={classNames(styles['bet-team'])}>ManUtd VS Arsenal</p>
                        {/* <div className='match-result'>Match Result</div> */}
                    </div>
                    
                    <div>
                        <div className={classNames(styles['bet-actions'])}>
                            <div className={classNames(styles['bet-odd'])}>
                                1.24
                            </div>
                            
                            {/* { getBoldIcon() } */}
                            <div className={classNames(styles['remove-bet'])}
                            //  onClick={()=>{handleClose(parseInt(props.groupId),props.eventId, props.objectId)}}
                             ><CloseIcon/></div>
                        </div>
                    </div>
                    
                </div>
                <div className={classNames(styles['match-result'])} >Match Result</div>
                <div className={classNames(styles['match-detail'])} >ManUtd VS Arsenal</div>
            </CardContent>

        </Card>
        <Card className={classNames(styles['bet-main-container'])} sx={{ width: "100%" }}>
            <CardContent>
                <div className={classNames(styles['bet-container'])}>
                    <div className={classNames(styles['bet-checkbox'])}>
                        <Checkbox 
                        // checked={getCheckedStatus(props.objectId)}
                            // onChange={event=>handleCheckboxChange(event,parseInt(props.groupId),props.eventId, props.objectId)} 
                            {...label} 
                        />
                    </div>
                    <div className={classNames(styles['bet-description'])}>
                        <p className={classNames(styles['bet-team'])}>ManUtd VS Arsenal</p>
                        {/* <div className='match-result'>Match Result</div> */}
                    </div>
                    
                    <div>
                        <div className={classNames(styles['bet-actions'])}>
                            <div className={classNames(styles['bet-odd'])}>
                                1.24
                            </div>
                            
                            {/* { getBoldIcon() } */}
                            <div className={classNames(styles['remove-bet'])}
                            //  onClick={()=>{handleClose(parseInt(props.groupId),props.eventId, props.objectId)}}
                             ><CloseIcon/></div>
                        </div>
                    </div>
                    
                </div>
                <div className={classNames(styles['match-result'])} >Match Result</div>
                <div className={classNames(styles['match-detail'])} >ManUtd VS Arsenal</div>
            </CardContent>

        </Card>
        <Card className={classNames(styles['bet-main-container'])} sx={{ width: "100%" }}>
            <CardContent>
                <div className={classNames(styles['bet-container'])}>
                    <div className={classNames(styles['bet-checkbox'])}>
                        <Checkbox 
                        // checked={getCheckedStatus(props.objectId)}
                            // onChange={event=>handleCheckboxChange(event,parseInt(props.groupId),props.eventId, props.objectId)} 
                            {...label} 
                        />
                    </div>
                    <div className={classNames(styles['bet-description'])}>
                        <p className={classNames(styles['bet-team'])}>ManUtd VS Arsenal</p>
                        {/* <div className='match-result'>Match Result</div> */}
                    </div>
                    
                    <div>
                        <div className={classNames(styles['bet-actions'])}>
                            <div className={classNames(styles['bet-odd'])}>
                                1.24
                            </div>
                            
                            {/* { getBoldIcon() } */}
                            <div className={classNames(styles['remove-bet'])}
                            //  onClick={()=>{handleClose(parseInt(props.groupId),props.eventId, props.objectId)}}
                             ><CloseIcon/></div>
                        </div>
                    </div>
                    
                </div>
                <div className={classNames(styles['match-result'])} >Match Result</div>
                <div className={classNames(styles['match-detail'])} >ManUtd VS Arsenal</div>
            </CardContent>

        </Card>
        <Card className={classNames(styles['bet-main-container'])} sx={{ width: "100%" }}>
            <CardContent>
                <div className={classNames(styles['bet-container'])}>
                    <div className={classNames(styles['bet-checkbox'])}>
                        <Checkbox 
                        // checked={getCheckedStatus(props.objectId)}
                            // onChange={event=>handleCheckboxChange(event,parseInt(props.groupId),props.eventId, props.objectId)} 
                            {...label} 
                        />
                    </div>
                    <div className={classNames(styles['bet-description'])}>
                        <p className={classNames(styles['bet-team'])}>ManUtd VS Arsenal</p>
                        {/* <div className='match-result'>Match Result</div> */}
                    </div>
                    
                    <div>
                        <div className={classNames(styles['bet-actions'])}>
                            <div className={classNames(styles['bet-odd'])}>
                                1.24
                            </div>
                            
                            {/* { getBoldIcon() } */}
                            <div className={classNames(styles['remove-bet'])}
                            //  onClick={()=>{handleClose(parseInt(props.groupId),props.eventId, props.objectId)}}
                             ><CloseIcon/></div>
                        </div>
                    </div>
                    
                </div>
                <div className={classNames(styles['match-result'])} >Match Result</div>
                <div className={classNames(styles['match-detail'])} >ManUtd VS Arsenal</div>
            </CardContent>

        </Card>
      </List>

        <div style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
            <Button variant="outlined" style={{color:'white', backgroundColor:'#1a2035', border:'1px solid #1a2035'}}>Place Bet</Button>
        </div>  
    </Box>
  );

  return (
    <div>
        
        <React.Fragment key={'right'}>
          {/* <Button onClick={toggleDrawer('right', true)}>{'right'}</Button> */}
          <SwipeableDrawer
            anchor={'right'}
            open={isOpen}
            onClose={onClose}
            onOpen={() => {}} 
          >
            {list('right')}
          </SwipeableDrawer>
        </React.Fragment>

    </div>
  );
}


export default RightSidebar;
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Header from '../Header/Header';
import styles from './WithdrawRequests.module.css';
import { ThemeProvider } from '@emotion/react';
import { Button, Grid, createTheme } from '@mui/material';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NavLink } from 'react-router-dom';


const WithdrawRequests  = () => {
    const getRowHeight = () => { return 150 };

    const columns: GridColDef[] = [
      { field: 'id', headerName: 'PunterID', 
      renderCell: (params) => (
        <NavLink to="/CustomerInformation" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', color:'#99ccff' }}>
            {params.value}
          </div>
        </NavLink>
  ),
      // width: 70 
      },
      { field: 'name', headerName: 'Name',
       width: 150 
      },
      { field: 'amount', headerName: 'Amount', 
      // width: 130 
      },
      { field: 'surcharge', headerName: 'Surcharge', 
      width: 140 
      },
      { field: 'description', headerName: 'Description', 
        width: 430, 
        renderCell: (params) => (
        <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
        ),
      },
      { field: 'type', headerName: 'Type', 
      width: 140 
      },
      { field: 'requested', headerName: 'Requested', 
      // width: 130 
      },
      {
        field: 'makeDone',
        headerName: 'Make Done',
        width: 210,
        renderCell: (params) => (
          <>
          <div style={{display:'flex', flexDirection:'column'}}>
          <div >
          <NavLink to="/WithdrawMoney" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginRight: 16 }}
              // onClick={() => handleGoClick(params.id)}
            >
              Go
            </Button>
          </NavLink>
          <NavLink to="/CancelWithdraw" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <Button
              variant="outlined"
              color="secondary"
              size="small"
              // onClick={() => handleCancelClick(params.id)}
            >
              Cancel
            </Button>
          </NavLink>
          </div>

          <NavLink to="/ChangeShop" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginTop: 16 }}
              // onClick={() => handleGoClick(params.id)}
            >
              Change Shop
            </Button>
          </NavLink>

          </div>

          </>
        ),
      },
    ];

    const columns2: GridColDef[] = [
      { field: 'id', headerName: 'PunterID', 
      renderCell: (params) => (
        <NavLink to="/CustomerInformation" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', color:'#99ccff' }}>
            {params.value}
          </div>
        </NavLink>
  ),
      width: 170 
      },
      { field: 'name', headerName: 'Name',
       width: 170 
      },
      { field: 'amount', headerName: 'Amount', 
      width: 170 
      },
      { field: 'surcharge', headerName: 'Surcharge', 
      width: 180 
      },
      { field: 'type', headerName: 'Type', 
      width: 180 
      },
      { field: 'requested', headerName: 'Requested', 
      width: 170
      },
      { field: 'adminName', headerName: 'Admin Name', 
      width: 200 
      },
      { field: 'done', headerName: 'Done', 
      width: 200 
      },
    ];

    const columns3: GridColDef[] = [
      { field: 'id', headerName: 'PunterID', 
      width: 150 ,
      renderCell: (params) => (
        <NavLink to="/CustomerInformation" style={{textDecoration:'none'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', color:'#99ccff' }}>
            {params.value}
          </div>
        </NavLink>
  ),
      },
      { field: 'name', headerName: 'Name',
       width: 180 
      },
      { field: 'amount', headerName: 'Amount', 
      width: 210 
      },
      { field: 'surcharge', headerName: 'Surcharge', 
      width: 200 
      },
      { field: 'requested', headerName: 'Requested', 
      width: 180 
      },
      { field: 'type', headerName: 'Type', 
      width: 180
      },
      { field: 'adminName', headerName: 'AdminName', 
      width: 180 
      },
      {
        field: 'cancelled',
        headerName: 'Cancelled',
        width: 170,
        renderCell: (params) => (
          <>
          </>
        ),
      },
    ];

    const rows = [
      {
        id: 101,
        name: "John Doe",
        amount: 100.00,
        surcharge: 5.00,
        description: "	Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-20",
        // makeDone: true
      },
      {
        id: 102,
        name: "Jane Smith",
        amount: 200.00,
        surcharge: 10.00,
        description: "	Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-19",
        // makeDone: false
      },
      {
        id: 103,
        name: "Mike Johnson",
        amount: 150.00,
        surcharge: 7.50,
        description: "	Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-18",
        // makeDone: true
      },
      {
        id: 104,
        name: "Emily Clarke",
        amount: 250.00,
        surcharge: 12.50,
        description: "Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-17",
        // makeDone: false
      },
      {
        id: 105,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        description: "Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-16",
        // makeDone: true
      },
      {
        id: 106,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        description: "Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-16",
        // makeDone: true
      },
      {
        id: 107,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        description: "Money to customer: 200 XAU Name: ThisName ThatName Shop name: haehaehr Shop Amount: 200 XAU Requested Payout date: 2023-03-10 Surcharge: 0 XAU Payout: 200 XAU E-mail is sent to the registered address when the money is booked at haehaehr Shop SMS message is sent to the registered mobile when the money is booked at haehaehr Shop Additional info:test2",
        type: "type1",
        requested: "2024-02-16",
        // makeDone: true
      }
    ];

    const rows2 = [
      {
        id: 101,
        name: "John Doe",
        amount: 100.00,
        surcharge: 5.00,
        adminName: "user2",
        type:'type 1',
        done:'yes',
        requested: "2024-02-20",
        // makeDone: true
      },
      {
        id: 102,
        name: "Jane Smith",
        amount: 200.00,
        surcharge: 10.00,
        adminName: "user2",
        type:'type 1',
        done:'yes',
        requested: "2024-02-19",
        shopName: "LuckyStrike",
        
        // makeDone: false
      },
      {
        id: 103,
        name: "Mike Johnson",
        amount: 150.00,
        surcharge: 7.50,
        adminName: "user2",
        type:'type 1',
        done:'no',
        requested: "2024-02-18",
        
        // makeDone: true
      },
      {
        id: 104,
        name: "Emily Clarke",
        amount: 250.00,
        surcharge: 12.50,
        adminName: "user2",
        type:'type 1',
        done:'yes',
        requested: "2024-02-17",
        
        // makeDone: false
      },
      {
        id: 105,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user2",
        type:'type 1',
        requested: "2024-02-16",
        done: "yes",
        // makeDone: true
      },
      {
        id: 106,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user1",
        type:'type 1',
        done: "yes",
        requested: "2024-02-16",
        // makeDone: true
      },
      {
        id: 107,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user1",
        type:'type 1',
        done: "yes",
        requested: "2024-02-16",
        // makeDone: true
      }
    ];

    const rows3 = [
      {
        id: 101,
        name: "John Doe",
        amount: 100.00,
        surcharge: 5.00,
        adminName: "user2",
        done:'',
        requested: "2024-02-20",
        type: "BetItAll",
        // makeDone: true
      },
      {
        id: 102,
        name: "Jane Smith",
        amount: 200.00,
        surcharge: 10.00,
        adminName: "user2",
        done:'',
        requested: "2024-02-19",
        type: "LuckyStrike",
        // makeDone: false
      },
      {
        id: 103,
        name: "Mike Johnson",
        amount: 150.00,
        surcharge: 7.50,
        adminName: "user2",
        done:'',
        requested: "2024-02-18",
        type: "HighRollers",
        // makeDone: true
      },
      {
        id: 104,
        name: "Emily Clarke",
        amount: 250.00,
        surcharge: 12.50,
        adminName: "user2",
        done:'',
        requested: "2024-02-17",
        type: "FortuneFalls",
        // makeDone: false
      },
      {
        id: 105,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user2",
        requested: "2024-02-16",
        shopNtypeame: "PokerPro",
        type: "PokerPro",
        // makeDone: true
      },
      {
        id: 106,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user1",
        requested: "2024-02-16",
        type: "PokerPro",
        // makeDone: true
      },
      {
        id: 107,
        name: "Alex Turner",
        amount: 300.00,
        surcharge: 15.00,
        adminName: "user1",
        requested: "2024-02-16",
        type: "PokerPro",
        // makeDone: true
      }
    ];

    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
    });

  return (
    <>
    <Header/>
    <ThemeProvider theme={darkTheme}>
        <div className={styles.mainContainer}>
            <div className={styles.subHeader}>
                <h4>Managed Terminal Withdrawal Requests</h4>
            </div>
            <div className={styles.parent}>
            {/* <div style={{ height: 400, width: '100%' }}> */}
            <DataGrid rows={rows} columns={columns} getRowHeight={getRowHeight} initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
                        
            {/* </div> */}
            <Button style={{margin:'2%'}} variant="contained" color="primary" size="small"
              // onClick={() => handleCancelClick(params.id)}
            >
              MASS GO!
            </Button>

            </div>
            <div className={styles.subHeader}>
                <h4>Requested Betting Terminal Withdraws</h4>
            </div>
            <div className={styles.dateRangeContainer}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2}>
                <Grid item>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="From">
                      <DatePicker />
                    </DemoItem>
                  </DemoContainer>
                </Grid>
                <Grid item>
                  <DemoContainer components={['DatePicker']}>
                    <DemoItem label="To">
                      <DatePicker />
                    </DemoItem>
                  </DemoContainer>
                </Grid>
              </Grid>
            </LocalizationProvider>
            </div>
            <div className={styles.parent}>
            {/* <div style={{ height: 400, width: '100%' }}> */}
            <DataGrid rows={rows2} columns={columns2} getRowHeight={()=>(50)} initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
            <div className={styles.subHeader}>
                <h4>Cancelled Requests</h4>
            </div>
            <DataGrid rows={rows3} columns={columns3} getRowHeight={()=>(50)} initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />       
            {/* </div> */}
            </div>
        </div>
        </ThemeProvider>
        </>
  );
};

export default WithdrawRequests;

import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate, useLocation} from 'react-router-dom';
import Sidebar from './components/sidebar/sidebar';
import Home from './components/Home/Home';
import styles from './theme.module.css';
import DetailContainer from './components/DetailContainer/DetailContainer';
import DepositContainer from './components/DepositContainer/DepositContainer';
import LedgerReport from './components/LedgerReport/LedgerReport';
import TerminalWithdrawRequests from './components/TerminalWithdrawRequests/TerminalWithdrawRequests';
import AccountReport from './components/AccountReport/AccountReport';
import WithdrawRequests from './components/WithdrawRequests/WithdrawRequests';
import BlockedLogins from './components/BlockedLogins/BlockedLogins';
import AccountCallCenter from './components/AccountCallCenter/AccountCallCenter';
import BetMonitor from './components/BetMonitor/BetMonitor';
import MTA from './components/MTA/MTA';
import OnlineDeposits from './components/OnlineDeposits/OnlineDeposits';
import ShopDetails from './components/ShopDetails/ShopDetails';
import AgentReport from './components/AgentReport/AgentReport';
import AccountWorkingArea from './components/AccountWorkingArea/AccountWorkingArea';
import Transaction from './components/Customer/Transaction/Transaction';
import BetStatistics from './components/Customer/BetStatistics/BetStatistics';
import DepositMoney from './components/Customer/DepositMoney/DepositMoney';
import WithdrawMoney from './components/Customer/WithdrawMoney/WithdrawMoney';
import ChangeShop from './components/Customer/ChangeShop/ChangeShop';
import SendEmail from './components/Customer/SendEmail/SendEmail';
import ApproveWithdrawRequest from './components/TerminalWithdrawRequests/ApproveWithdrawRequest/ApproveWithdrawRequest';
import SetNewPassword from './components/ForgotPassword/ForgotPasswordEmailConformation/ForgotPasswordEmailConformation';
import CustomerInformation from './components/Customer/CustomerInformation/CustomerInformation';
import CancelWithdraw from './components/WithdrawRequests/CancelWithdraw/CancelWithdraw';
import PreviousLoginAttempts from './components/BlockedLogins/PreviousLoginAttempts/PreviousLoginAttempts';
import ResetPassword from './components/ResetPassword/ResetPassword';
import useSessionManagement from './components/Session/useSessionManagement';
import Login from './components/Login/Login';
import ConfirmRegistration from './components/Registration/ConfirmRegistration';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import SignUpOverlay from './components/Overlay/SignupOverlay/SignupOverlay';

function App() {
    useSessionManagement();
    const location = useLocation();
    
    const excludedPaths = [
      "/register-confirm",
      "/forgot-password",
      "/reset-password",
      "/set-new-password",
      "/login",
      "/register"
  ];
  let showHeaderAndFooter = !excludedPaths.includes(location.pathname);
    return (
            <div className={styles.appContainer}>
                {showHeaderAndFooter && <Sidebar/>}
                <div className={styles.mainContent}>
                    <Routes>
                        <Route path="/" element={< Navigate replace to = "/EnterBet" />}/>
                        <Route path="/EnterBet" element={< Home />}/>
                        <Route path="/AccountCallCenter" element={< AccountCallCenter />}/>
                        <Route path="/home/:id" element={< DetailContainer />}/>
                        <Route path="/deposit" element={< DepositContainer />}/>
                        <Route path="/LedgerReport" element={< LedgerReport />}/>
                        <Route
                            path="/TerminalWithdrawRequests"
                            element={< TerminalWithdrawRequests />}/>
                        <Route path="/WithdrawRequests" element={< WithdrawRequests />}/>
                        <Route path="/BlockedLogins" element={< BlockedLogins />}/>
                        <Route path="/AccountReport" element={< AccountReport />}/>
                        <Route path="/BetMonitor" element={< BetMonitor />}/>
                        <Route path="/MTA" element={< MTA />}/>
                        <Route path="/OnlineDeposits" element={< OnlineDeposits />}/>
                        <Route path="/ShopDetails" element={< ShopDetails />}/>
                        <Route path="/AgentReport" element={< AgentReport />}/>
                        <Route path="/AccountWorkingArea" element={< AccountWorkingArea />}/>
                        <Route path="/UserTransaction" element={< Transaction />}/>
                        <Route path="/BetStatistics" element={< BetStatistics />}/>
                        <Route path="/MoneyDeposit" element={< DepositMoney />}/>
                        <Route path="/WithdrawMoney" element={< WithdrawMoney />}/>
                        <Route path="/ChangeShop" element={< ChangeShop />}/>
                        <Route path="/SendEmail" element={< SendEmail />}/>
                        <Route path="/ApproveWithdrawRequest" element={< ApproveWithdrawRequest />}/>
                        <Route path="/CustomerInformation" element={< CustomerInformation />}/>
                        <Route path="/CancelWithdraw" element={< CancelWithdraw />}/>
                        <Route path="/PreviousLoginAttempts" element={< PreviousLoginAttempts />}/>
                        <Route path="/ResetPassword" element={< ResetPassword />}/>
                        <Route path="/Login" element={< Login />}/>
                        <Route path="register-confirm" element={< ConfirmRegistration />}/>
                        <Route path="forgot-password" element={< ForgotPassword />}/>
                        <Route path="set-new-password" element={< ResetPassword />}/>
                        <Route path="reset-password" element={< SetNewPassword />}/>
                        <Route path="register" element={< SignUpOverlay />}/>
                    </Routes>
                </div>
            </div>
    );
}

export default App;

import React from 'react';
import {
    Box,
    Container,
    Button,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    TextField as MuiTextField,
    styled,
    TextField
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './SendEmail.module.css';

const darktheme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const CustomTextField = styled(MuiTextField)({
    '& .MuiInputBase-input': {
        height: 40,
        padding: '0 14px',
        fontSize: '0.875rem'
    },
    '& .MuiInputLabel-root': {
        lineHeight: '1.4375em',
        fontSize: '0.875rem'
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)'
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
    }
});

const Deposit = () => {
    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={darktheme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Typography
                            style={{
                            padding: '2% 0%'
                        }}
                            variant="h5"
                            gutterBottom>
                            Account Working Area
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    padding={2}
                                    boxShadow={3}
                                    borderRadius={2}
                                    sx={{
                                    border: '1px solid grey'
                                }}>
                                    <Typography variant="h6">Deposit</Typography>
                                    <CustomTextField
                                        label="Sender's Address"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Receiver's Address"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField label="Subject" variant="outlined" fullWidth margin="normal"/>
                                    <TextField
                                        style={{margin:'2% 0%'}}
                                        id="outlined-multiline-static"
                                        label="Body"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        defaultValue=""/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        <Button
                                            style={{
                                            margin: '2%',
                                            marginTop: '4.6%'
                                        }}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Send Mail
                                        </Button>
                                    </div>
                                </Box>

                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default Deposit
import React from 'react';
import {
    Box,
    Container,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './BetStatistics.module.css'

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});


const BetStatistics = () => {
  return (
    <div style={{
        width: '84vw'
    }}>
        <Header/>
    <ThemeProvider theme={theme}>
    <div className={styles.mainContainer}>
        <Container maxWidth="lg">
            <Typography style={{padding:'2% 0%'}} variant="h5" gutterBottom>
                Account Working Area
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                        <Typography variant="h6" gutterBottom>Punters Bet statistic</Typography>
                        <Typography variant="body1" gutterBottom>Number of placed bets: -81.00 XAU</Typography>
                        <Typography variant="body1" gutterBottom>Number of pending: 0</Typography>
                        <Typography variant="body1" gutterBottom>Number of wins: 1</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                        <Typography variant="h6" gutterBottom>Total Sum</Typography>
                        <Typography variant="body1" gutterBottom>Bet Sum: -81.00 XAU</Typography>
                        <Typography variant="body1" gutterBottom>Pending Sum: 10</Typography>
                        <Typography variant="body1" gutterBottom>Results:1 </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
        </div>
    </ThemeProvider>
    </div>
  )
}

export default BetStatistics
import {
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography
} from '@mui/material'
import React from 'react'
import styles from './BetSpeedTab.module.css'

interface DataRow {
  DatePeriod: string;
  totalBets: number;
  TerminalBets: number;
  InternetBets: number;
  percentInternet: number; 
}

type Column = {
id: keyof DataRow;
label: string;
};

const columns: Column[] = [
  { id: 'DatePeriod', label: 'Date Period' },
  { id: 'totalBets', label: 'Total Bets' },
  { id: 'TerminalBets', label: 'Terminal Bets' },
  { id: 'InternetBets', label: 'Internet Bets' },
  { id: 'percentInternet', label: 'Percent Internet' },
];

const dummyData: DataRow[] = [
  {
    DatePeriod: "2024-03-01T00:00:00Z",
    totalBets: 100,
    TerminalBets: 40,
    InternetBets: 60,
    percentInternet: 60,
  },
  {
    DatePeriod: "2024-03-02T00:00:00Z",
    totalBets: 150,
    TerminalBets: 50,
    InternetBets: 100,
    percentInternet: 66.67,
  },
  {
    DatePeriod: "2024-03-01T00:00:00Z",
    totalBets: 100,
    TerminalBets: 40,
    InternetBets: 60,
    percentInternet: 60,
  },
  {
    DatePeriod: "2024-03-02T00:00:00Z",
    totalBets: 150,
    TerminalBets: 50,
    InternetBets: 100,
    percentInternet: 66.67,
  },
  {
    DatePeriod: "2024-03-01T00:00:00Z",
    totalBets: 100,
    TerminalBets: 40,
    InternetBets: 60,
    percentInternet: 60,
  },
  {
    DatePeriod: "2024-03-02T00:00:00Z",
    totalBets: 150,
    TerminalBets: 50,
    InternetBets: 100,
    percentInternet: 66.67,
  },
  {
    DatePeriod: "2024-03-01T00:00:00Z",
    totalBets: 100,
    TerminalBets: 40,
    InternetBets: 60,
    percentInternet: 60,
  },
  {
    DatePeriod: "2024-03-02T00:00:00Z",
    totalBets: 150,
    TerminalBets: 50,
    InternetBets: 100,
    percentInternet: 66.67,
  },
];

type Order = 'asc' | 'desc';

const BetSpeedTab = () => {

// eslint-disable-next-line
const [selectedColumns, setSelectedColumns] = React.useState(columns.map(column => column.id));

const [order,
  setOrder] = React.useState <Order > ('asc');
const [orderBy,
  setOrderBy] = React.useState < keyof DataRow > ('DatePeriod');
const [page,
  setPage] = React.useState(0);
const [rowsPerPage,
  setRowsPerPage] = React.useState(5);

const handleRequestSort = (property : keyof DataRow) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc
      ? 'desc'
      : 'asc');
  setOrderBy(property);
};

const sortedData = () : DataRow[] => {
  return dummyData.sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
          return order === 'asc'
              ? -1
              : 1;
      }
      if (a[orderBy] > b[orderBy]) {
          return order === 'asc'
              ? 1
              : -1;
      }
      return 0;
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

const handleChangePage = (event : unknown, newPage : number) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};

  const betDetails = {
      selection: 'Day',
      FromDate: '2022-02-03 13:56',
      ToDate: '2022-02-03 13:56',
      BookFilter: 'pstbet',
  };


  return (
      <div>
          <div className={styles.dateRangeContainer}>
              <Grid container spacing={2}>
                  <TextField
                      style={{
                      marginTop: '3.8%',
                      marginLeft: '1%'
                  }}
                      id="outlined-basic"
                      label="Bet Identity"
                      variant="outlined"/>
                  <Button
                      style={{
                      margin: '2%',
                      marginTop: '4.6%'
                  }}
                      variant="contained"
                      color="primary"
                      size="small">
                      Get Data
                  </Button>
              </Grid>

          </div>
          <Card
              sx={{
              minWidth: 275,
              margin: '20px'
          }}>
              <CardContent>
                  <Typography
                      sx={{
                      fontSize: 14
                  }}
                      color="text.secondary"
                      gutterBottom>
                      Bet Details
                  </Typography>
                  <Grid container spacing={1}>
                      {Object
                          .entries(betDetails)
                          .map(([key, value]) => (
                              <Grid item xs={8} sm={6} key={key}>
                                  <Typography variant="body2">
                                      {key}: {value}
                                  </Typography>
                              </Grid>
                          ))}
                  </Grid>
              </CardContent>
          </Card>
          <TableContainer
                      component={Paper}
                      style={{
                  }}>
                      <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                              <TableRow>
                                  {columns
                                      .filter(column => selectedColumns.includes(column.id))
                                      .map((column) => (
                                          <TableCell
                                              key={column.id}
                                              sortDirection={orderBy === column.id
                                              ? order
                                              : false}>
                                              <TableSortLabel
                                                  active={orderBy === column.id}
                                                  direction={orderBy === column.id
                                                  ? order
                                                  : 'asc'}
                                                  onClick={() => handleRequestSort(column.id)}>
                                                  {column.label}
                                              </TableSortLabel>
                                          </TableCell>
                                      ))}
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {sortedData().map((row, index) => (
                                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                      {columns
                                          .filter(column => selectedColumns.includes(column.id))
                                          .map((column) => {
                                              const value = row[column.id];
                                              return (
                                                  <TableCell key={column.id}>
                                                      {value}
                                                  </TableCell>
                                              );
                                          })}
                                  </TableRow>
                              ))}
                          </TableBody>
                      </Table>
                  </TableContainer>
                  <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={dummyData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}/>

      </div>
  )
}

export default BetSpeedTab
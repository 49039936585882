import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ThemeProvider} from '@emotion/react';
import Header from '../Header/Header';
import styles from './ResetPassword.module.css';
import {Button, FormControl, InputLabel, OutlinedInput, createTheme} from '@mui/material';
import {useAppDispatch} from '../../redux/hooks';
import {useNavigate} from 'react-router-dom';
import {changePassword} from '../../redux/thunk/dataThunk';

interface TabPanelProps {
    children?: React.ReactNode;
    index : number;
    value : number;
}

function CustomTabPanel(props : TabPanelProps) {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{
                    p: 3
                }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index : number) {
    return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

export default function MTA() {
    const [value,
        setValue] = React.useState(0);
        const [currentPassword,
            setCurrentPassword] = React.useState('');
            const [newPassword,
                setNewPassword] = React.useState('');
            const [passwordError,
                setPasswordError] = React.useState(false);
            const [confirmNewPassword,
                setConfirmNewPassword] = React.useState('');
            const [isPasswordMatch,
                setIsPasswordMatch] = React.useState(true);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleChange = (event : React.SyntheticEvent, newValue : number) => {
        setValue(newValue);
    };

    const handleSubmit = (event : React.FormEvent < HTMLFormElement >) => {
        event.preventDefault();
        
        dispatch(changePassword(currentPassword,newPassword))
        setNewPassword('');
        setCurrentPassword('');
        setConfirmNewPassword('');
    };
    const validatePassword = (password : string) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleClick = (event : any) => {
        event.preventDefault();
        dispatch(changePassword(currentPassword, newPassword))
    }

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    const handleOldPasswordChange = (event : React.ChangeEvent < HTMLInputElement >) => {
        setCurrentPassword(event.target.value);
    };

    const handlePasswordChange = (event : React.ChangeEvent < HTMLInputElement >) => {
        const newPassword = event.target.value;
        setNewPassword(newPassword);
        setPasswordError(!validatePassword(newPassword));
        setIsPasswordMatch(newPassword === confirmNewPassword);
    };

    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={darkTheme}>
                <div className={styles.mainContainer}>

                    <Box sx={{
                        width: '100%'
                    }}>
                        <Box
                            sx={{
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Reset Password" {...a11yProps(0)}/>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className={styles.dateRangeContainer}>
                                <FormControl
                                    style={{
                                    margin: '1% 30%',
                                    width: '30%'
                                }}
                                    fullWidth
                                    sx={{
                                    m: 1
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Old Password</InputLabel>
                                    <OutlinedInput
                                        value={currentPassword}
                                        data-testid="current-password-input"
                                        onChange={handleOldPasswordChange}
                                        type='password'
                                        id="outlined-adornment-amount"
                                        label="Current Password"/>
                                </FormControl>
                                <FormControl
                                    style={{
                                    margin: '1% 30%',
                                    width: '30%'
                                }}
                                    fullWidth
                                    sx={{
                                    m: 1
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Create New Password</InputLabel>
                                    <OutlinedInput
                                        type='password'
                                        id="outlined-adornment-amount"
                                        label="Create New Password"/>
                                </FormControl>
                                <FormControl
                                    style={{
                                    margin: '1% 30%',
                                    width: '30%'
                                }}
                                    fullWidth
                                    sx={{
                                    m: 1
                                }}>
                                    <InputLabel
                                        htmlFor="outlined-adornment-amount"
                                        style={{
                                        marginRight: '4%'
                                    }}>Confirm New password</InputLabel>
                                    <OutlinedInput
                                        type='password'
                                        id="outlined-adornment-amount"
                                        label="Confirm New password"/>
                                </FormControl>
                                <div
                                    style={{
                                    margin: '1% 30%',
                                    width: '30%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                        margin: '2% 1%'
                                    }}
                                        onClick={handleClick}
                                        color="primary">Set New Password</Button>
                                </div>
                            </div>
                        </CustomTabPanel>
                    </Box>
                </div>
            </ThemeProvider>
        </div>
    );
}

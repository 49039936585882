import React, {useState} from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    FormControl,
    OutlinedInput,
    InputLabel
} from '@mui/material';
import { NavLink } from 'react-router-dom';

interface DataRow {
    Terminal : string;
    UserName : string;
    Balance : number;
    Created : string;
    Valid : string;
    ValidEndDate : string;
    MTAID : number;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'Terminal',
        label: 'Terminal'
    }, {
        id: 'UserName',
        label: 'UserName'
    }, {
        id: 'Balance',
        label: 'Balance'
    }, {
        id: 'Created',
        label: 'Created (Date)'
    }, {
        id: 'Valid',
        label: 'Valid'
    }, {
        id: 'ValidEndDate',
        label: 'Valid EndDate'
    }, {
        id: 'MTAID',
        label: 'MTAID'
    }
];

const dummyData : DataRow[] = [
    {
        Terminal: "Terminal 1",
        UserName: "user1",
        Balance: 100.00,
        Created: "2023-01-01T10:00:00Z",
        Valid: "Yes",
        ValidEndDate: "2023-12-31T23:59:59Z",
        MTAID: 101
    }, {
        Terminal: "Terminal 2",
        UserName: "user2",
        Balance: 200.00,
        Created: "2023-02-01T11:00:00Z",
        Valid: "No",
        ValidEndDate: "2023-06-30T23:59:59Z",
        MTAID: 102
    }, {
        Terminal: "Terminal 1",
        UserName: "user1",
        Balance: 100.00,
        Created: "2023-01-01T10:00:00Z",
        Valid: "Yes",
        ValidEndDate: "2023-12-31T23:59:59Z",
        MTAID: 101
    }, {
        Terminal: "Terminal 2",
        UserName: "user2",
        Balance: 200.00,
        Created: "2023-02-01T11:00:00Z",
        Valid: "No",
        ValidEndDate: "2023-06-30T23:59:59Z",
        MTAID: 102
    }, {
        Terminal: "Terminal 1",
        UserName: "user1",
        Balance: 100.00,
        Created: "2023-01-01T10:00:00Z",
        Valid: "Yes",
        ValidEndDate: "2023-12-31T23:59:59Z",
        MTAID: 101
    }, {
        Terminal: "Terminal 2",
        UserName: "user2",
        Balance: 200.00,
        Created: "2023-02-01T11:00:00Z",
        Valid: "No",
        ValidEndDate: "2023-06-30T23:59:59Z",
        MTAID: 102
    }
];

type Order = 'asc' | 'desc';

const AgentsTab : React.FC = () => {
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('Created');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <FormControl fullWidth style={{
                margin: '2% 0%'
            }}>
                <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
                <OutlinedInput id="outlined-adornment-amount" label="Search"/>
            </FormControl>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        sx={{
                        backgroundColor: 'black',
                        '& .MuiTableCell-head': {
                            color: 'common.white'
                        }
                    }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id
                                    ? order
                                    : false}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id
                                        ? order
                                        : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((row, index) => (
                            <TableRow hover key={index}>
                                {columns.map((column) =>  {
                                                if (column.id === 'UserName') {
                                                    return (  <TableCell key={column.id}>
                                                        <NavLink to = "/AccountWorkingArea" style={{textDecoration:'none', color:'lightblue'}} >
                                                        {row[column.id]}
                                                        </NavLink>
                                                    </TableCell> )
                                                }
                                                 else {
                                                    return <TableCell key={column.id}>
                                                        {row[column.id]}
                                                    </TableCell>
                                                }

                                            })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dummyData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </TableContainer>
        </div>
    );
};

export default AgentsTab;

import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField
} from '@mui/material'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'
import React, {useState} from 'react'
import styles from './CashOutTab.module.css';
import {NavLink} from 'react-router-dom'

interface DataRow {
    userName : string;
    PunterBetID : string;
    BetID : string;
    BetTime : string;
    CashOutTime : string;
    BetAmount : number;
    OriginalOdds : number;
    Odds : number;
    CBets : number;
    CWon : number;
    Amount : number;
    Fee : number;
    Net : number;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'userName',
        label: 'User Name'
    }, {
        id: 'PunterBetID',
        label: 'Punter Bet ID'
    }, {
        id: 'BetID',
        label: 'Bet ID'
    }, {
        id: 'BetTime',
        label: 'Bet Time'
    }, {
        id: 'CashOutTime',
        label: 'Cash Out Time'
    }, {
        id: 'BetAmount',
        label: 'Bet Amount'
    }, {
        id: 'OriginalOdds',
        label: 'Original Odds'
    }, {
        id: 'Odds',
        label: 'Odds'
    }, {
        id: 'CBets',
        label: 'C.Bets'
    }, {
        id: 'CWon',
        label: 'C.Won'
    }, {
        id: 'Amount',
        label: 'Amount'
    }, {
        id: 'Fee',
        label: 'Fee'
    }, {
        id: 'Net',
        label: 'Net'
    }
];

const dummyData = [
    {
        userName: "user1",
        PunterBetID: "PB123",
        BetID: "B123",
        BetTime: "2022-12-01 12:00",
        CashOutTime: "2022-12-02 15:00",
        BetAmount: 100,
        OriginalOdds: 1.5,
        Odds: 1.4,
        CBets: 2,
        CWon: 1,
        Amount: 140,
        Fee: 5,
        Net: 135
    }, {
        userName: "user2",
        PunterBetID: "PB124",
        BetID: "B124",
        BetTime: "2022-12-02 13:20",
        CashOutTime: "2022-12-03 16:30",
        BetAmount: 200,
        OriginalOdds: 2.0,
        Odds: 1.8,
        CBets: 3,
        CWon: 2,
        Amount: 360,
        Fee: 10,
        Net: 350
    }, {
        userName: "user1",
        PunterBetID: "PB123",
        BetID: "B123",
        BetTime: "2022-12-01 12:00",
        CashOutTime: "2022-12-02 15:00",
        BetAmount: 100,
        OriginalOdds: 1.5,
        Odds: 1.4,
        CBets: 2,
        CWon: 1,
        Amount: 140,
        Fee: 5,
        Net: 135
    }, {
        userName: "user2",
        PunterBetID: "PB124",
        BetID: "B124",
        BetTime: "2022-12-02 13:20",
        CashOutTime: "2022-12-03 16:30",
        BetAmount: 200,
        OriginalOdds: 2.0,
        Odds: 1.8,
        CBets: 3,
        CWon: 2,
        Amount: 360,
        Fee: 10,
        Net: 350
    }, {
        userName: "user1",
        PunterBetID: "PB123",
        BetID: "B123",
        BetTime: "2022-12-01 12:00",
        CashOutTime: "2022-12-02 15:00",
        BetAmount: 100,
        OriginalOdds: 1.5,
        Odds: 1.4,
        CBets: 2,
        CWon: 1,
        Amount: 140,
        Fee: 5,
        Net: 135
    }, {
        userName: "user2",
        PunterBetID: "PB124",
        BetID: "B124",
        BetTime: "2022-12-02 13:20",
        CashOutTime: "2022-12-03 16:30",
        BetAmount: 200,
        OriginalOdds: 2.0,
        Odds: 1.8,
        CBets: 3,
        CWon: 2,
        Amount: 360,
        Fee: 10,
        Net: 350
    }, {
        userName: "user1",
        PunterBetID: "PB123",
        BetID: "B123",
        BetTime: "2022-12-01 12:00",
        CashOutTime: "2022-12-02 15:00",
        BetAmount: 100,
        OriginalOdds: 1.5,
        Odds: 1.4,
        CBets: 2,
        CWon: 1,
        Amount: 140,
        Fee: 5,
        Net: 135
    }, {
        userName: "user2",
        PunterBetID: "PB124",
        BetID: "B124",
        BetTime: "2022-12-02 13:20",
        CashOutTime: "2022-12-03 16:30",
        BetAmount: 200,
        OriginalOdds: 2.0,
        Odds: 1.8,
        CBets: 3,
        CWon: 2,
        Amount: 360,
        Fee: 10,
        Net: 350
    },
    // Add more objects for each bet as needed
];

type Order = 'asc' | 'desc';

type ChildComponentProps = {
    setValue: React.Dispatch<React.SetStateAction<number>>;
  };

const CashOutTab: React.FC<ChildComponentProps> = ({ setValue }) => {

    // eslint-disable-next-line
    const [selectedColumns, setSelectedColumns] = useState(columns.map(column => column.id));
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('BetTime');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <div className={styles.dateRangeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="From">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>
                        </Grid>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="To">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>

                        </Grid>
                        <TextField
                            style={{
                            marginTop: '3.8%',
                            marginLeft: '1%'
                        }}
                            id="outlined-basic"
                            label="Top Max"
                            variant="outlined"/>

                        <Button
                            style={{
                            margin: '2%',
                            marginTop: '4.6%'
                        }}
                            variant="contained"
                            color="primary"
                            size="small">
                            Get Data
                        </Button>

                    </Grid>
                </LocalizationProvider>
                <div className={styles.tableContainer}>
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns
                                        .filter(column => selectedColumns.includes(column.id))
                                        .map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={orderBy === column.id
                                                ? order
                                                : false}>
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id
                                                    ? order
                                                    : 'asc'}
                                                    onClick={() => handleRequestSort(column.id)}>
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData().map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns
                                            .filter(column => selectedColumns.includes(column.id))
                                            .map((column) => {
                                                if (column.id === 'userName') {
                                                    return (  <TableCell key={column.id}>
                                                        < NavLink to = "/AccountWorkingArea" style={{textDecoration:'none', color:'lightblue'}} className = {
                                                        ({isActive}) => isActive
                                                            ? styles.activeLink
                                                            : ''
                                                    } >
                                                        {row[column.id]}
                                                        </NavLink>
                                                    </TableCell> )
                                                }
                                                if (column.id === 'BetID') {
                                                    return <TableCell style={{cursor:'pointer'}} key={column.id} onClick={() => setValue(1)}>
                                                        {row[column.id]}
                                                    </TableCell>
                                                } else {
                                                    return <TableCell key={column.id}>
                                                        {row[column.id]}
                                                    </TableCell>
                                                }

                                            })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={dummyData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}/>
                </div>

            </div>
        </div>
    )
}

export default CashOutTab
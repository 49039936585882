import React from 'react';
import {
    Box,
    Container,
    Button,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    TextField as MuiTextField,
    styled,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    useTheme,
    SelectChangeEvent,
    Theme,
    OutlinedInput
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './DepositMoney.module.css';

const darktheme = createTheme({
    palette: {
        mode: 'dark'
    }
});


const CustomTextField = styled(MuiTextField)({
    '& .MuiInputBase-input': {
        height: 40, 
        padding: '0 14px', 
        fontSize: '0.875rem',
    },
    '& .MuiInputLabel-root': {
        lineHeight: '1.4375em', 
        fontSize: '0.875rem',
    },
    '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 14px) scale(1)',
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)'
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const names = ['Sparkasse', 'Commmerz Bank'];

function getStyles(name : string, personName : string[], theme : Theme) {
    return {
        fontWeight: personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium
    };
}

const Deposit = () => {

    const theme = useTheme();
    const [personName,
        setPersonName] = React.useState < string[] > ([]);

    const handleChange = (event : SelectChangeEvent < typeof personName >) => {
        const {target: {
                value
            }} = event;
        setPersonName(
        typeof value === 'string'
            ? value.split(',')
            : value,);
    };

    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={darktheme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Typography
                            style={{
                            padding: '2% 0%'
                        }}
                            variant="h5"
                            gutterBottom>
                            Account Working Area
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    padding={2}
                                    boxShadow={3}
                                    borderRadius={2}
                                    sx={{
                                    border: '1px solid grey'
                                }}>
                                    <Typography variant="h6">Deposit</Typography>
                                    <div
                                        style={{
                                        display: 'flex',
                                        marginTop: '2%'
                                    }}>
                                        <FormControl
                                            sx={{
                                            m: 1,
                                            width: 525
                                        }}>
                                            <InputLabel id="demo-multiple-name-label">Bank</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={personName}
                                                onChange={handleChange}
                                                input={< OutlinedInput label = "Name" />}
                                                MenuProps={MenuProps}>
                                                {names.map((name) => (
                                                    <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>

                                        </FormControl>
                                        <CustomTextField
                                            style={{
                                            margin: '1%'
                                        }}
                                            label="Amount XAU"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"/>
                                        <CustomTextField
                                            style={{
                                            margin: '1%'
                                        }}
                                            label="Amount Other"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"/>
                                    </div>

                                    <CustomTextField
                                        label="Bonus Amount"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Correction "
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Cancelled Suspected Fraud"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Partner Commission"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Cancelled Fee"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Defer payment"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Cancelled CashOut"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Total Amount"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                    <CustomTextField
                                        label="Transaction Text"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"/>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                        <Button
                                        style={{
                                        margin: '2%',
                                        marginTop: '4.6%'
                                    }}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        Confirm
                                    </Button>
                                        </div>
                                    
                                </Box>

                            </Grid>
                        </Grid>

                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default Deposit
import React from 'react';
import {
    Box,
    Container,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    Divider
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './ApproveWithdrawRequest.module.css'

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const ApproveWithdrawRequest = () => {
    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={theme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Typography
                            style={{
                            padding: '2% 0%'
                        }}
                            variant="h5"
                            gutterBottom>
                            Account Working Area
                        </Typography>

                        {/* <Grid container spacing={2}> */}
                        <Grid >
                            <Box
                                padding={2}
                                boxShadow={3}
                                borderRadius={2}
                                sx={{
                                border: '1px solid grey'
                            }}>
                                <Typography variant="h6" gutterBottom>Punters Bet Statistic</Typography>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Transaction ID:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>2253806</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Requestcode:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>JGRNFKBX</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Status:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Ready for payout</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Payout date:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>2023-03-10 00:00</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Admin approved (amount):</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Manual Approved by admin</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Approved by:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Eugeny</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Requested:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>2023-03-09 11:50</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Amount:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>-200.00</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Surcharge:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>0</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Managed:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Under process</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Description:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Money to customer: 200 XAU<br/>
                                        Name: ThisName ThatName
                                        <br/>
                                        Shop name: haehaehr Shop
                                        <br/>
                                        Amount: 200 XAU
                                        <br/>
                                        Requested Payout date: 2023-03-10
                                        <br/>
                                        Surcharge: 0 XAU
                                        <br/>
                                        Payout: 200 XAU
                                        <br/>
                                        E-mail is sent to the registered address when the money is booked at haehaehr
                                        Shop
                                        <br/>
                                        SMS message is sent to the registered mobile when the money is booked at
                                        haehaehr Shop
                                        <br/>
                                        Additional info:test2</Typography>
                                </div>
                            </Box>
                        </Grid>
                        {/* </Grid> */}
                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default ApproveWithdrawRequest
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Header from '../Header/Header';
import styles from './BlockedLogins.module.css';
import { ThemeProvider } from '@emotion/react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, createTheme, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TransitionProps } from '@mui/material/transitions';
import { NavLink } from 'react-router-dom';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WithdrawRequests  = () => {
    // const getRowHeight = () => { return 150 };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const columns: GridColDef[] = [
      { field: 'cancel', headerName: '', 
      width: 120, 
      renderCell: (params) => (
      <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis',cursor:'pointer' }}>
        <CloseIcon onClick={handleClickOpen}/>
      </div>
      ),
      },
      { field: 'client', headerName: 'Client',
       width: 150 
      },
      { field: '', headerName: ' ', 
      width: 60, 
      renderCell: (params) => (
        <NavLink to="/PreviousLoginAttempts" style={{textDecoration:'none', color:'white'}} className={({ isActive }) => isActive ? styles.activeLink : ''}>
          <div style={{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <EditNoteIcon/>
          </div>
        </NavLink>

      ),
      },
      { field: 'fails', headerName: 'Fails', 
      // width: 130 
      },
      { field: 'lastFail', headerName: 'Last Fall', 
      width: 240 
      },
      { field: 'logins', headerName: 'Logins', 
        width: 130, 
      },
      { field: 'lastLogin', headerName: 'Last Login', 
      width: 200 
      },
      { field: 'id', headerName: 'CustomerID', 
      width: 140 
      },
    ];


    const rows = [
      {
        client: "Alpha Corp",
        fails: 2,
        lastFail: "2023-10-01T12:34:56Z",
        logins: 5,
        lastLogin: "2023-10-10T08:30:00Z",
        id: 1001
      },
      {
        client: "Beta Limited",
        fails: 0,
        lastFail: "2023-10-01T12:34:56Z",
        logins: 12,
        lastLogin: "2023-10-11T15:45:00Z",
        id: 1002
      },
      {
        client: "Gamma Inc",
        fails: 3,
        lastFail: "2023-09-29T09:20:00Z",
        logins: 8,
        lastLogin: "2023-10-05T11:00:00Z",
        id: 1003
      },
      {
        client: "Delta Services",
        fails: 1,
        lastFail: "2023-10-02T14:00:00Z",
        logins: 15,
        lastLogin: "2023-10-12T16:30:00Z",
        id: 1004
      },
      {
        client: "Epsilon LLC",
        fails: 4,
        lastFail: "2023-08-25T10:15:00Z",
        logins: 10,
        lastLogin: "2023-10-01T09:45:00Z",
        id: 1005
      }
    ];

    const darkTheme = createTheme({
      palette: {
        mode: 'dark',
      },
    });

  return (
    <>
    <Header/>
    <ThemeProvider theme={darkTheme}>
        <div className={styles.mainContainer}>
            <div className={styles.subHeader}>
                <h4>Blocked Logins</h4>
            </div>
            <div className={styles.parent}>
            <DataGrid rows={rows} columns={columns} getRowHeight={()=>(50)} initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection
            />
            
            <Button style={{margin:'2%'}} variant="contained" color="primary" size="small">
              Reset Selected Logins
            </Button>
            

            </div>
        </div>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Reset login confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          Do you really want to reset login Kalle3 [Auto Signup 2020-03-27 17:33:09 Auto Signup]?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleClose} variant="contained">Reset Login</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
    </>
  );
};

export default WithdrawRequests;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ThemeProvider} from '@emotion/react';
import Header from '../Header/Header';
import styles from './MTA.module.css';
import {
    Button,
    FormControl,
    InputLabel,
    OutlinedInput,
    createTheme,
} from '@mui/material';
import AgentsTab from './AgentsTab/AgentsTab';

interface TabPanelProps {
    children?: React.ReactNode;
    index : number;
    value : number;
}

function CustomTabPanel(props : TabPanelProps) {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{
                    p: 3
                }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index : number) {
    return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

export default function MTA() {
    const [value,
        setValue] = React.useState(0);

    const handleChange = (event : React.SyntheticEvent, newValue : number) => {
        setValue(newValue);
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={darkTheme}>
                <div className={styles.mainContainer}>
                    <div className={styles.subHeader}>
                        <h4>MTA</h4>
                    </div>
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Box
                            sx={{
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Create MTA" {...a11yProps(0)}/>
                                <Tab label="Agents" {...a11yProps(1)}/>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className={styles.dateRangeContainer}>
                                <FormControl
                                    fullWidth
                                    sx={{
                                    m: 1
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Punter UserName</InputLabel>
                                    <OutlinedInput id="outlined-adornment-amount" label="Punter UserName"/>
                                </FormControl>
                                <FormControl
                                    fullWidth
                                    sx={{
                                    m: 1
                                }}>
                                    <InputLabel htmlFor="outlined-adornment-amount">Terminal UserName</InputLabel>
                                    <OutlinedInput id="outlined-adornment-amount" label="Terminal UserName"/>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    style={{
                                    margin: '2% 1%'
                                }}
                                    color="primary">Create MTA</Button>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <AgentsTab/>
                        </CustomTabPanel>
                    </Box>
                </div>
            </ThemeProvider>
        </div>
    );
}

import {
    Button,
    Card,
    CardContent,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography
} from '@mui/material'
import React from 'react'
import styles from './ViewBetDetailsTab.module.css'

interface DataRow {
    league : string;
    category : string;
    shortDescription : string;
    endBetDate : string;
    eventID : string;
    selection : string;
    ObjOdds : number;
    lastObjOdds : number;
    expectedWinners : number;
    winner : string;
    winnerAdmin : string;
    objStatus : string;
    winnerTimestamp : string;
    EventActive : number;
    EventSusp : string;
    EventUpd : string;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'league',
        label: 'League'
    }, {
        id: 'category',
        label: 'Category'
    }, {
        id: 'shortDescription',
        label: 'Short Description'
    }, {
        id: 'endBetDate',
        label: 'End Bet Date'
    }, {
        id: 'eventID',
        label: 'Event ID'
    }, {
        id: 'selection',
        label: 'Selection'
    }, {
        id: 'ObjOdds',
        label: 'Odds'
    }, {
        id: 'lastObjOdds',
        label: 'Last Odds'
    }, {
        id: 'expectedWinners',
        label: 'Expected Winners'
    }, {
        id: 'winner',
        label: 'Winner'
    }, {
        id: 'winnerAdmin',
        label: 'Winner Admin'
    }, {
        id: 'objStatus',
        label: 'Status'
    }, {
        id: 'winnerTimestamp',
        label: 'Winner Timestamp'
    }, {
        id: 'EventActive',
        label: 'Event Active'
    }, {
        id: 'EventSusp',
        label: 'Event Suspended'
    }, {
        id: 'EventUpd',
        label: 'Event Updated'
    }
];

const dummyData : DataRow[] = [
    {
        league: "Premier League",
        category: "Football",
        shortDescription: "Match between Team A and Team B",
        endBetDate: "2024-03-01T20:00:00Z",
        eventID: "EVT123456",
        selection: "Team A Win",
        ObjOdds: 1.75,
        lastObjOdds: 1.65,
        expectedWinners: 1,
        winner: "Team A",
        winnerAdmin: "AdminName1",
        objStatus: "Closed",
        winnerTimestamp: "2024-03-01T22:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-02-28T12:00:00Z"
    }, {
        league: "NBA",
        category: "Basketball",
        shortDescription: "Game between Team X and Team Y",
        endBetDate: "2024-03-05T23:00:00Z",
        eventID: "EVT789012",
        selection: "Team Y Win",
        ObjOdds: 2.10,
        lastObjOdds: 2.00,
        expectedWinners: 1,
        winner: "Team Y",
        winnerAdmin: "AdminName2",
        objStatus: "Open",
        winnerTimestamp: "2024-03-06T01:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-03-02T15:00:00Z"
    }, {
        league: "Premier League",
        category: "Football",
        shortDescription: "Match between Team A and Team B",
        endBetDate: "2024-03-01T20:00:00Z",
        eventID: "EVT123456",
        selection: "Team A Win",
        ObjOdds: 1.75,
        lastObjOdds: 1.65,
        expectedWinners: 1,
        winner: "Team A",
        winnerAdmin: "AdminName1",
        objStatus: "Closed",
        winnerTimestamp: "2024-03-01T22:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-02-28T12:00:00Z"
    }, {
        league: "NBA",
        category: "Basketball",
        shortDescription: "Game between Team X and Team Y",
        endBetDate: "2024-03-05T23:00:00Z",
        eventID: "EVT789012",
        selection: "Team Y Win",
        ObjOdds: 2.10,
        lastObjOdds: 2.00,
        expectedWinners: 1,
        winner: "Team Y",
        winnerAdmin: "AdminName2",
        objStatus: "Open",
        winnerTimestamp: "2024-03-06T01:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-03-02T15:00:00Z"
    }, {
        league: "Premier League",
        category: "Football",
        shortDescription: "Match between Team A and Team B",
        endBetDate: "2024-03-01T20:00:00Z",
        eventID: "EVT123456",
        selection: "Team A Win",
        ObjOdds: 1.75,
        lastObjOdds: 1.65,
        expectedWinners: 1,
        winner: "Team A",
        winnerAdmin: "AdminName1",
        objStatus: "Closed",
        winnerTimestamp: "2024-03-01T22:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-02-28T12:00:00Z"
    }, {
        league: "NBA",
        category: "Basketball",
        shortDescription: "Game between Team X and Team Y",
        endBetDate: "2024-03-05T23:00:00Z",
        eventID: "EVT789012",
        selection: "Team Y Win",
        ObjOdds: 2.10,
        lastObjOdds: 2.00,
        expectedWinners: 1,
        winner: "Team Y",
        winnerAdmin: "AdminName2",
        objStatus: "Open",
        winnerTimestamp: "2024-03-06T01:00:00Z",
        EventActive: 1,
        EventSusp: '',
        EventUpd: "2024-03-02T15:00:00Z"
    }
];

type Order = 'asc' | 'desc';

const ViewBetDetails = () => {


    // eslint-disable-next-line
    const [selectedColumns, setSelectedColumns] = React.useState(columns.map(column => column.id));

    const [order,
        setOrder] = React.useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = React.useState < keyof DataRow > ('league');
    const [page,
        setPage] = React.useState(0);
    const [rowsPerPage,
        setRowsPerPage] = React.useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const betDetails = {
        BetId: '2235431',
        PunterBetId: 'NAD-287387-190',
        UserName: 'psttest1',
        BetTimeStamp: '2022-02-03 13:56',
        BetStatus: 'Won',
        BetStatusDate: '2023-07-27 18:29',
        BetOdds: '55.76',
        FirstCombOdds: '55.76',
        StakeLocal: '100.00',
        StakeGBP: '4.81',
        PunterMaxPercent: '100.00',
        BetSource: 'Mobile'
    };

    return (
        <div>
            <div className={styles.dateRangeContainer}>
                <Grid container spacing={2}>
                    <TextField
                        style={{
                        marginTop: '3.8%',
                        marginLeft: '1%'
                    }}
                        id="outlined-basic"
                        label="Bet Identity"
                        variant="outlined"/>
                    <Button
                        style={{
                        margin: '2%',
                        marginTop: '4.6%'
                    }}
                        variant="contained"
                        color="primary"
                        size="small">
                        Get Data
                    </Button>
                </Grid>

            </div>
            <Card
                sx={{
                minWidth: 275,
                margin: '20px'
            }}>
                <CardContent>
                    <Typography
                        sx={{
                        fontSize: 14
                    }}
                        color="text.secondary"
                        gutterBottom>
                        Bet Details
                    </Typography>
                    <Grid container spacing={1}>
                        {Object
                            .entries(betDetails)
                            .map(([key, value]) => (
                                <Grid item xs={8} sm={6} key={key}>
                                    <Typography variant="body2">
                                        {key}: {value}
                                    </Typography>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </Card>
            <TableContainer
                component={Paper}
                style={{
                border: '1px solid rgba(81, 81, 81, 1)'
            }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns
                                .filter(column => selectedColumns.includes(column.id))
                                .map((column) => (
                                    <TableCell
                                        sx={{
                                        backgroundColor: '#1f283e',
                                        color: 'white'
                                    }}
                                        key={column.id}
                                        sortDirection={orderBy === column.id
                                        ? order
                                        : false}>
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id
                                            ? order
                                            : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}>
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((row, index) => (
                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                {columns
                                    .filter(column => selectedColumns.includes(column.id))
                                    .map((column) => {
                                        const value = row[column.id];
                                        return (
                                            <TableCell
                                                key={column.id}
                                                sx={{
                                                backgroundColor: '#1f283e',
                                                color: 'white'
                                            }}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dummyData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}/>

        </div>
    )
}

export default ViewBetDetails
import { createSlice } from '@reduxjs/toolkit';

const initialState : { isEmailSent : boolean, ispasswordChanged: boolean } = {
    isEmailSent: false,
    ispasswordChanged: false
};

export const registerSlice = createSlice({
  name: 'register',
  initialState: initialState,
  reducers: {
    emailSentSuccessfully: state => {
        state.isEmailSent = true;
    },
    passwordChangedSuccessfully: state => {
        state.ispasswordChanged = true;
    }
  }
});

export const { emailSentSuccessfully, passwordChangedSuccessfully } = registerSlice.actions;
export default registerSlice.reducer;

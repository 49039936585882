import React from 'react';
import {
    Box,
    Container,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    Divider
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './CustomerInformation.module.css'

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const CustomerInformation = () => {
    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={theme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Typography
                            style={{
                            padding: '2% 0%'
                        }}
                            variant="h5"
                            gutterBottom>
                            Information - CustomerID XAU-585368
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    padding={2}
                                    boxShadow={3}
                                    borderRadius={2}
                                    sx={{
                                    border: '1px solid grey'
                                }}>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>
                                            CustomerID:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>XAU-585368</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Title:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Mr</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>First Name:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Lage</Typography>
                                    </div>

                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Last Name:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Bergström</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Nickname:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>LageB</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Birthdate:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>13/08/1990</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Street1:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Potsdam
                                        </Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Street2:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Jageralle</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Zipcode:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>12345</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>City:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Stockholm</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>State:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Hessen</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Country:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Sweden</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Email:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>lage123@gmail.com</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>CellNumber:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>+49 1234567898</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Balance:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>12345 USD</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Created:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>12/23/23</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>WantInfo:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Yes</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>MaxBet:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>0</Typography>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box
                                    padding={2}
                                    boxShadow={3}
                                    borderRadius={2}
                                    sx={{
                                    border: '1px solid grey'
                                }}>

                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>MaxBetPercent:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>30%</Typography>
                                    </div>

                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>NewMaxDep:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>0</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>NewMaxDep. Date:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom></Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Credit Card Fails:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>0</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Last Changed:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>22/10/23</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Login Count:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>5</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Last Login Date:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>02/03/2024</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>AU Agreement:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Yes</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Virtual Balance:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>1234</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Member:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Yes</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Failed Login:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>0</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Last Failed Login:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>11/12/2022</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Active:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Yes</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Suspended:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>No</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Suspend Description:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom></Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Updated by Admin:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>Eugeny</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Currency:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>XAU</Typography>
                                    </div>
                                    <Divider/>
                                    <div
                                        style={{
                                        display: 'flex',
                                        alignItems: 'space-between'
                                    }}>
                                        <Typography
                                            style={{
                                            marginLeft: '6%',
                                            width: '200px'
                                        }}
                                            variant="body1"
                                            gutterBottom>Partner:</Typography>
                                        <Typography
                                            style={{
                                            marginLeft: '12%'
                                        }}
                                            variant="body1"
                                            gutterBottom>pstbet</Typography>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default CustomerInformation
import React from 'react';
import {
    Box,
    Container,
    Button,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    Divider
} from '@mui/material';
import Header from '../../Header/Header';
import styles from './CancelWithdraw.module.css'

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const CancelWithdraw = () => {
    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={theme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Typography
                            style={{
                            padding: '2% 0%'
                        }}
                            variant="h5"
                            gutterBottom>
                            Account Working Area
                        </Typography>

                        {/* <Grid container spacing={2}> */}
                        <Grid >
                            <Box
                                padding={2}
                                boxShadow={3}
                                borderRadius={2}
                                sx={{
                                border: '1px solid grey'
                            }}>
                                <Typography variant="h6" gutterBottom>Punter Information - PunterID</Typography>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Amount:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>-200</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Surcharge:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>0</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Requeststamp:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>09/03/2023 11:50:26</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Punter Name:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Lage B</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Typename:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Withdrawal Betting Terminal
                                    </Typography>

                                </div>

                            </Box>
                        </Grid>
                        <div
                            style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '2% 0%'
                        }}>
                            <Button variant="outlined" color="secondary" size="small">
                                Cancel request!
                            </Button>
                        </div>

                        {/* </Grid> */}
                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default CancelWithdraw
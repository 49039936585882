import React, {useState} from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import styles from './PreviousLoginAttempts.module.css';
import Header from '../../Header/Header';

interface DataRow {
    id: number;
    regDate: string;
    logType: string;
    details: string;
    ipAddress: string;
    device: string;
    url: string;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns: Column[] = [
    { id: 'regDate', label: 'Reg Date' },
    { id: 'logType', label: 'Log Type' },
    { id: 'details', label: 'Details' },
    { id: 'ipAddress', label: 'IP Address' },
    { id: 'device', label: 'Device' },
    { id: 'url', label: 'URL' },
];


const dummyData: DataRow[] = [
    { id:1,regDate: '2024-03-01', logType: 'Login Attempt', details: 'Successful login', ipAddress: '192.168.1.1', device: 'Desktop', url: 'https://example.com/login' },
    { id:2,regDate: '2024-03-02', logType: 'Logout', details: 'User initiated logout', ipAddress: '192.168.1.2', device: 'Mobile', url: 'https://example.com/logout' },
    { id:3,regDate: '2024-03-01', logType: 'Login Attempt', details: 'Successful login', ipAddress: '192.168.1.1', device: 'Desktop', url: 'https://example.com/login' },
    { id:4,regDate: '2024-03-02', logType: 'Logout', details: 'User initiated logout', ipAddress: '192.168.1.2', device: 'Mobile', url: 'https://example.com/logout' },
    { id:5,regDate: '2024-03-01', logType: 'Login Attempt', details: 'Successful login', ipAddress: '192.168.1.1', device: 'Desktop', url: 'https://example.com/login' },
    { id:6,regDate: '2024-03-02', logType: 'Logout', details: 'User initiated logout', ipAddress: '192.168.1.2', device: 'Mobile', url: 'https://example.com/logout' },
    { id:7,regDate: '2024-03-01', logType: 'Login Attempt', details: 'Successful login', ipAddress: '192.168.1.1', device: 'Desktop', url: 'https://example.com/login' },
    { id:8,regDate: '2024-03-02', logType: 'Logout', details: 'User initiated logout', ipAddress: '192.168.1.2', device: 'Mobile', url: 'https://example.com/logout' },
    { id:9,regDate: '2024-03-01', logType: 'Login Attempt', details: 'Successful login', ipAddress: '192.168.1.1', device: 'Desktop', url: 'https://example.com/login' },
    { id:10,regDate: '2024-03-02', logType: 'Logout', details: 'User initiated logout', ipAddress: '192.168.1.2', device: 'Mobile', url: 'https://example.com/logout' },
];

type Order = 'asc' | 'desc';

const PreviousLoginAttempts : React.FC = () => {
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('id');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    // const [storeName,
    //     setStoreName] = React.useState < string[] > ([]);

    // const handleChange = (event : SelectChangeEvent < typeof storeName >) => {
    //     const {target: {
    //             value
    //         }} = event;
    //     setStoreName(typeof value === 'string'
    //         ? value.split(',')
    //         : value,);
    // };

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    return (

        <div>
            <div style={{
                width: '84vw'
            }}>
                <Header/>
                <ThemeProvider theme={darkTheme}>
                    <div className={styles.mainContainer}>
                        <div className={styles.subHeader}>
                            <h4>LOG ATTEMPTS :- Kalle3 [Auto Signup 2020-03-27 17:33:09 Auto Signup]</h4>
                        </div>

                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={orderBy === column.id
                                                ? order
                                                : false}>
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id
                                                    ? order
                                                    : 'asc'}
                                                    onClick={() => handleRequestSort(column.id)}>
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData().map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id}>
                                                        {typeof value === 'boolean'
                                                            ? (value
                                                                ? 'Yes'
                                                                : 'No')
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dummyData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}/>
                        </TableContainer>
                    </div>

                </ThemeProvider>
            </div>
        </div>
    );
};

export default PreviousLoginAttempts;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import styles from './AccountCallCenter.module.css';
import { Button, FormControlLabel, FormGroup, TextField, Typography, } from '@mui/material';
import RightSidebar from '../RightSidebar/RightSidebar';
import { PhoneInput } from 'react-international-phone';
import { CountryDropdown } from 'react-country-region-selector';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from '../Header/Header';


const AccountCallCenter = () => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [country, setCountry] = useState('');
    
    const rows = [
      {
        NickName: "User123",
        Name: "John Doe",
        City: "New York",
        NrBets: 5,
        Balance: 150.00,
        ReferralCode: "XYZ123",
        SignUpDate: "2022-01-10"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "LuckyStrike",
        Name: "Mike Johnson",
        City: "Chicago",
        NrBets: 2,
        Balance: 75.25,
        ReferralCode: "DEF789",
        SignUpDate: "2022-05-20"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "LuckyStrike",
        Name: "Mike Johnson",
        City: "Chicago",
        NrBets: 2,
        Balance: 75.25,
        ReferralCode: "DEF789",
        SignUpDate: "2022-05-20"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "LuckyStrike",
        Name: "Mike Johnson",
        City: "Chicago",
        NrBets: 2,
        Balance: 75.25,
        ReferralCode: "DEF789",
        SignUpDate: "2022-05-20"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "LuckyStrike",
        Name: "Mike Johnson",
        City: "Chicago",
        NrBets: 2,
        Balance: 75.25,
        ReferralCode: "DEF789",
        SignUpDate: "2022-05-20"
      },
      {
        NickName: "BetMaster",
        Name: "Jane Smith",
        City: "Los Angeles",
        NrBets: 8,
        Balance: 200.50,
        ReferralCode: "ABC456",
        SignUpDate: "2022-03-15"
      },
      {
        NickName: "LuckyStrike",
        Name: "Mike Johnson",
        City: "Chicago",
        NrBets: 2,
        Balance: 75.25,
        ReferralCode: "DEF789",
        SignUpDate: "2022-05-20"
      }
      ];
    

      const textFieldSx = {
        width: '100%',
        '& label': {
          color: 'white', // Default label color
        },
        '& label.Mui-focused': {
          color: 'white', // Label color when the input is focused
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'white', // Border color
          },
          '&:hover fieldset': {
            borderColor: 'white', // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: 'white', // Border color when focused
          },
          '& input': {
            color: 'white', // Input text color
          },
        },
      };


  return (
    <>
      <Header/>
      {/* <ThemeProvider theme={darkTheme}> */}
      <div className={styles.mainContainer}>
            <div className={styles.subHeader}>
                <h4>PUNTER INFORMATION</h4>
            </div>
            <div className={styles.parent}>
              <div className={styles.firstChild} style={{ maxHeight: 758 }}>
                <div className={styles.mainContainer}>
                  <div className={styles.textBoxContainer}>
                    <div style={{width:'99%'}}><PhoneInput defaultCountry="ua"/></div>
                    <TextField label="CustomerID" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="PunterID" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="FirstName" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="LastName" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="Username" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="E-mail or Mobile" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <TextField label="BetID or Receipt Number" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <CountryDropdown classes={styles.countryDropdown} value={country} onChange={(val) => setCountry(val)} />
                    <Typography style={{ display:'flex ', alignItems: 'center', justifyContent: 'center', width:'100%' }} variant="h6" id="tableTitle" component="div" >
                      Balance
                    </Typography>
                    <div style={{ display:'flex' }}>
                      <TextField label="From$" size="small" fullWidth margin="normal" sx={textFieldSx} />
                      <Typography style={{display:'flex', alignItems:'center', justifyContent:'center',margin:'0% 5%'}}>-</Typography>
                      <TextField label="To$" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    </div>
                    <TextField label="Referral Code" size="small" fullWidth margin="normal" sx={textFieldSx} />
                    <Typography style={{ display:'flex ', alignItems: 'center', justifyContent: 'center', width:'100%' }} variant="h6" id="tableTitle" component="div" >
                      Register Date
                    </Typography>
                    <div style={{width:'100%'}}>
                    <LocalizationProvider  dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker sx={textFieldSx} label="From" />
                      </DemoContainer>
                    </LocalizationProvider>
                    </div>
                    <div style={{width:'100%'}}>
                    <LocalizationProvider  dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker sx={textFieldSx} label="To" />
                      </DemoContainer>
                    </LocalizationProvider>
                    </div>
                    <div style={{display:'flex',alignItems:'center', justifyContent:'center', width:'100%', marginTop:'4%'}}>
                    <Button variant="outlined" style={{color:'white',whiteSpace:'nowrap', backgroundColor:'#1a2035', marginTop:'2%', padding:'3.5% 4%', border:'1px solid #1a2035'}}>
                      Fill Date
                    </Button>
                    </div>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox />} label="Show Susp:" />
                    </FormGroup>
                    <div className={styles.buttonGroup}>
                      <Button variant="contained" style={{ backgroundColor:'#1a2035' }}>Search</Button>
                      <Button variant="contained" style={{ backgroundColor:'#1a2035' }}>Reset</Button>
                    </div>
                  </div>
                </div>
              </div>
              <TableContainer component={Paper} className={styles.secondChild} style={{ maxHeight: 900 }}>
                <Table>
                    <TableHead style={{backgroundColor:'#1a2035'}}
                      >
                        <TableRow>
                            <TableCell style={{ width: '17%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="left">NickName</TableCell>
                            <TableCell style={{ width: '16%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="left">Name</TableCell>
                            <TableCell style={{ width: '14%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="center">City</TableCell>
                            <TableCell style={{ width: '15%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="center">Nr. Bets</TableCell>
                            <TableCell style={{ width: '15%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="center">Balance</TableCell>
                            <TableCell style={{ width: '12%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="center">Referral Code</TableCell>
                            <TableCell style={{ width: '17%', whiteSpace:'nowrap' }} className={styles.headingStyle} align="center">Signup Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row, index) => (
                        <TableRow key={index}>
                            <NavLink to="/AccountWorkingArea" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                              <TableCell style={{ width: '17%', cursor:'pointer', color:'darkblue' }} className={styles.paddingLeft} align="left">
                                  {row.NickName} 
                              </TableCell>
                            </NavLink>
                            
                            <TableCell style={{ width: '16%' }} className={styles.paddingLeft} align="left">
                                 {row.Name} 
                            </TableCell>
                            <TableCell style={{ width: '14%', paddingLeft:'5%' }} className={styles.paddingLeft} align="left">
                                 {row.City} 
                            </TableCell>
                            <TableCell style={{ width: '15%',  paddingLeft:'7%' }} className={styles.paddingLeft} align="left">
                                 {row.NrBets} 
                            </TableCell>
                            <TableCell style={{ width: '15%', paddingLeft:'6%' }} className={styles.paddingLeft} align="left">
                                 {row.Balance} 
                            </TableCell>
                            <TableCell style={{ width: '12%',  paddingLeft:'4%' }} className={styles.paddingLeft} align="left">
                                 {row.ReferralCode} 
                            </TableCell>
                            <TableCell style={{ width: '17%', paddingLeft:'2%' }} className={styles.paddingLeft} align="left">
                                 {row.SignUpDate} 
                            </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
              </TableContainer>

            <RightSidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />

            </div>
      </div>
      {/* </ThemeProvider> */}
    </>
  );
};

export default AccountCallCenter;

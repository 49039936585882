import React, { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { refreshSessionToken } from '../../redux/thunk/dataThunk';
import { updateLastActivity, updateSession } from '../../redux/slices/SessionSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const useSessionManagement = () => {
  const dispatch = useAppDispatch();
  const session = useSelector((state : RootState) => state.session);
  // const session = useSelector(state => state.session);

  useEffect(() => {
    const handleActivity = () => {
      dispatch(updateLastActivity());
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, [dispatch]);

  // Periodically checking for inactivity 
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const inactivityTime = currentTime - session.lastActivity;
      const inactivityThreshold = 1800 * 1000; // 30 mins in milliseconds

      // Check if the session token needs to be refreshed based on activity
      const isActive = inactivityTime < session.session_duration * 60000;
      const isHalfwayThrough = inactivityTime > (session.session_duration / 2) * 60000;
      if (isActive && isHalfwayThrough) {
        console.log("refreshing token")
        dispatch(refreshSessionToken());
      }

      // if inactive then clear session 
      if (inactivityTime >= inactivityThreshold) {
        dispatch(updateSession({ 
          id: null, session_id: null, session_token: null, 
        }));
        console.log("session timed out removing token from local storage")
        localStorage.removeItem('session'); 
      }
    }, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [dispatch, session.lastActivity, session.session_duration]);
};

export default useSessionManagement;

// Sidebar.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.css';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import MonitorIcon from '@mui/icons-material/Monitor';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import PasswordIcon from '@mui/icons-material/Password';


function Sidebar() {
  return (
    <div className={styles.sidebar}>
      <Box sx={{ width: '100%', maxWidth: 360, bgcolor: '#1f283e', color:'white' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <NavLink to="/EnterBet" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <HomeIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Overview" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/deposit" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <EmailIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Send SMS" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/AccountCallCenter" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <MonetizationOnIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Account Call Center" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/TerminalWithdrawRequests" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <CheckCircleIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Terminal Withdraw Requests" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/WithdrawRequests" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <CheckCircleIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Withdraw Requests" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/BlockedLogins" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <PersonOffIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Blocked Logins" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/LedgerReport" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <AssessmentIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Ledger Report" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/BetMonitor" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <MonitorIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Bet Monitor" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/MTA" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <PersonIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="MTA" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/OnlineDeposits" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <CreditCardIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Online Deposits" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/ShopDetails" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <DescriptionIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Shop Details" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/AgentReport" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <PeopleIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Agent Report" />
                </ListItemButton>
            </NavLink>
          </ListItem>
          <Divider/>
          <ListItem disablePadding>
            <NavLink to="/ResetPassword" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                <ListItemButton>
                    <ListItemIcon>
                        <PasswordIcon style={{color:'white'}}/>
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </nav>
    </Box>
    </div>
  );
}

export default Sidebar;

import React from 'react';
import styles from './Header.module.css';
import {Button} from '@mui/material';
import {useAppDispatch} from '../../redux/hooks';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {signout} from '../../redux/thunk/dataThunk';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Header() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authToken = useSelector((state : RootState) => state.session.session_token);

    function signOut() {
        dispatch(signout(authToken as string));
    }
    function logIn() {
        navigate('/login');
    }
    return (
        <div className={styles.header}>
            <h1 className={styles.title}>Back Office</h1>
            <div className={styles.buttons}>

                {!authToken && <Button
                    variant="outlined"
                    style={{
                    color: 'white',
                    backgroundColor: '#1a2035',
                    border: '1px solid #1a2035'
                }}
                    onClick={logIn}>Login</Button>
}
                {authToken !== null && <Button
                    style={{
                    color: 'white',
                    backgroundColor: '#1a2035',
                    border: '1px solid #1a2035',
                    marginRight: '10px',
                    marginLeft: '15px'
                }}
                    onClick={signOut}>
                    Sign Out
                </Button>
}
                {authToken && <Button
                    style={{
                    color: 'white',
                    marginRight: '10px',
                    marginLeft: '15px'
                }}>
                    <AccountCircleIcon/>
                </Button>
}

            </div>
        </div>
    );
}

export default Header;

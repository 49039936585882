import * as React from 'react';
import {ThemeProvider} from '@emotion/react';
import Header from '../Header/Header';
import styles from './AgentReport.module.css';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    createTheme
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';

interface RowData {
    PID : string;
    Shop : string;
    BC : number;
    BA : number;
    CC : number;
    CA : number;
    DC : number;
    DA : number;
    WC : number;
    WA : number;
    WDRC : number;
    WDRA : number;
    Total : number;
    Rate : string;
    Bal : number;
}

interface SortConfig {
    key : keyof RowData;
    direction : 'asc' | 'desc';
}

const rows : RowData[] = [
    {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }, {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }, {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }, {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }, {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }, {
        PID: 'P123',
        Shop: 'Shop 1',
        BC: 100,
        BA: 500,
        CC: 2,
        CA: 10,
        DC: 50,
        DA: 250,
        WC: 20,
        WA: 100,
        WDRC: 5,
        WDRA: 25,
        Total: 120,
        Rate: '20%',
        Bal: 200
    }

];

const darkTheme = createTheme({
    palette: {
        mode: 'dark'
    }
});

export default function AgentReport() {
    const [sortConfig,
        setSortConfig] = React.useState < SortConfig > ({key: 'PID', direction: 'asc'});
    const [page,
        setPage] = React.useState(0);
    const [rowsPerPage,
        setRowsPerPage] = React.useState(5);

    const sortedRows = React.useMemo(() => {
        let sortableItems = [...rows];
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'asc'
                        ? -1
                        : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'asc'
                        ? 1
                        : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [sortConfig]);

    const requestSort = (key : keyof RowData) => {
        let direction : 'asc' | 'desc' = 'asc'; // Explicitly declare direction with the type 'asc' | 'desc'
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page with new rows per page
    };

    const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <div style={{
            width: '84vw'
        }}>

            <Header/>
            <ThemeProvider theme={darkTheme}>
                <div className={styles.mainContainer}>
                    <div className={styles.subHeader}>
                        <h4>Agent Report</h4>
                    </div>
                    <div className={styles.dateRangeContainer}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <DemoContainer components={['DatePicker']}>
                                        <DemoItem label="From">
                                            <DatePicker/>
                                        </DemoItem>
                                    </DemoContainer>
                                </Grid>
                                <Grid item>
                                    <DemoContainer components={['DatePicker']}>
                                        <DemoItem label="To">
                                            <DatePicker/>
                                        </DemoItem>
                                    </DemoContainer>

                                </Grid>
                                <Button
                                    style={{
                                    margin: '2%',
                                    marginTop: '4.6%'
                                }}
                                    variant="contained"
                                    color="primary"
                                    size="small">
                                    Get Data
                                </Button>
                            </Grid>
                        </LocalizationProvider>
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead
                            sx={{
                                backgroundColor: 'black',
                                '& .MuiTableCell-head': {
                                    color: 'common.white'
                                }
                            }}>
                                <TableRow>
                                    <TableCell
                                        style={{
                                        paddingLeft: '2%'
                                    }}
                                        colSpan={2}>Shop/Terminal</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '4%'
                                    }}
                                        colSpan={2}>Bets</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '3%'
                                    }}
                                        colSpan={2}>Cancelled</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '3%'
                                    }}
                                        colSpan={2}>Deposits</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '3%'
                                    }}
                                        colSpan={2}>Win Payouts</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '4%'
                                    }}
                                        colSpan={2}>Withdrawals</TableCell>
                                    <TableCell
                                        style={{
                                        paddingLeft: '7%'
                                    }}
                                        colSpan={3}>Stats</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'PID'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('PID')}>
                                            PID
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'Shop'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('Shop')}>
                                            Shop
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'BC'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('BC')}>
                                            BC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'BA'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('BA')}>
                                            BA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'CC'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('CC')}>
                                            CC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'CA'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('CA')}>
                                            CA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'DC'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('DC')}>
                                            DC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'DA'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('DA')}>
                                            DA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'WC'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('WC')}>
                                            WC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'WA'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('WA')}>
                                            WA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'WDRC'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('WDRC')}>
                                            WDRC
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'WDRA'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('WDRA')}>
                                            WDRA
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'Total'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('Total')}>
                                            Total
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'Rate'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('Rate')}>
                                            Rate
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'Bal'}
                                            direction={sortConfig.direction}
                                            onClick={() => requestSort('Bal')}>
                                            Bal
                                        </TableSortLabel>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedRows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{row.PID}</TableCell>
                                        <TableCell>{row.Shop}</TableCell>
                                        <TableCell>{row.BC}</TableCell>
                                        <TableCell>{row.BA}</TableCell>
                                        <TableCell>{row.CC}</TableCell>
                                        <TableCell>{row.CA}</TableCell>
                                        <TableCell>{row.DC}</TableCell>
                                        <TableCell>{row.DA}</TableCell>
                                        <TableCell>{row.WC}</TableCell>
                                        <TableCell>{row.WA}</TableCell>
                                        <TableCell>{row.WDRC}</TableCell>
                                        <TableCell>{row.WDRA}</TableCell>
                                        <TableCell>{row.Total}</TableCell>
                                        <TableCell>{row.Rate}</TableCell>
                                        <TableCell>{row.Bal}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}/>
                    </TableContainer>
                </div>
            </ThemeProvider>
        </div>
    );
}

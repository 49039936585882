import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
  id: number | null;
  session_id: string | null;
  session_token: string | null;
  session_duration: number;
  name: string | null;
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  last_login_date: string | null;
  lastActivity: number;
}

const initialState: SessionState = {
  id: null,
  session_id: null,
  session_token: null,
  session_duration: 120,
  name: null,
  first_name: null,
  last_name: null,
  email: null,
  last_login_date: null,
  lastActivity: Date.now()
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
      updateSession: (state, action: PayloadAction<Partial<SessionState>>) => {
        console.log("entered into update")
        const data = action.payload;
        // console.log("data from session slice",action.payload);
        // console.log("token from session slice",state.session_token)
        Object.keys(data).forEach((key) => {
          const sessionKey = key as keyof SessionState;
          if (sessionKey in state) {
            const newValue = data[sessionKey];
            if (typeof newValue === 'string' || typeof newValue === 'number') {
              (state[sessionKey] as any) = newValue;
            }
          }
        });

        localStorage.setItem('session', JSON.stringify(state));
      },
      removeSession: (state) => {
        state.id = null;
        state.session_id = null;
        state.session_token = null;
        state.name = null;
        state.first_name = null;
        state.last_name = null;
        state.email = null;
        state.last_login_date = null;
      },
      updateLastActivity: (state) => {
        state.lastActivity = Date.now();
      },
    },
  });

export const { updateSession, updateLastActivity, removeSession } = sessionSlice.actions;

export default sessionSlice.reducer;

import React, {useState} from 'react';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    InputLabel,
    OutlinedInput,
    FormControl,
    styled,
    Dialog,
    Typography,
    DialogContent,
    DialogTitle,
    IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import styles from './OnlinePayments.module.css';
import DepositDetails from '../DepositDetails/DepositDetails';
import { NavLink } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

interface DataRow {
    CreditID : number;
    CustomerID : number;
    Amount : number;
    PunterName : string;
    CompleteTime : string;
    SuccessCode : string;
    TransactionCode : string;
    IPAddress : string;
    Days7 : number;
    Days30 : number;
    Balance : number;
    MTAId : number;
    PunterCreated : string;
    LoginCount : number;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'CreditID',
        label: 'Credit ID'
    }, {
        id: 'CustomerID',
        label: 'Customer ID'
    }, {
        id: 'Amount',
        label: 'Amount'
    }, {
        id: 'PunterName',
        label: 'Punter Name'
    }, {
        id: 'CompleteTime',
        label: 'Complete Time'
    }, {
        id: 'SuccessCode',
        label: 'Success Code'
    }, {
        id: 'TransactionCode',
        label: 'Transaction Code'
    }, {
        id: 'IPAddress',
        label: 'IP Address'
    }, {
        id: 'Days7',
        label: '7 Days'
    }, {
        id: 'Days30',
        label: '30 Days'
    }, {
        id: 'Balance',
        label: 'Balance'
    }, {
        id: 'MTAId',
        label: 'MTA Id'
    }, {
        id: 'PunterCreated',
        label: 'Punter Created'
    }, {
        id: 'LoginCount',
        label: 'Login Count'
    }
];

const dummyData : DataRow[] = [
    {
        CreditID: 1,
        CustomerID: 100,
        Amount: 500,
        PunterName: "John Doe",
        CompleteTime: "2023-01-01 12:00",
        SuccessCode: "Success",
        TransactionCode: "TX123456",
        IPAddress: "192.168.1.1",
        Days7: 1500,
        Days30: 3000,
        Balance: 4500,
        MTAId: 123,
        PunterCreated: "2022-12-01",
        LoginCount: 15
    }, {
        CreditID: 1,
        CustomerID: 100,
        Amount: 500,
        PunterName: "John Doe",
        CompleteTime: "2023-01-01 12:00",
        SuccessCode: "Success",
        TransactionCode: "TX123456",
        IPAddress: "192.168.1.1",
        Days7: 1500,
        Days30: 3000,
        Balance: 4500,
        MTAId: 123,
        PunterCreated: "2022-12-01",
        LoginCount: 15
    }, {
        CreditID: 1,
        CustomerID: 100,
        Amount: 500,
        PunterName: "John Doe",
        CompleteTime: "2023-01-01 12:00",
        SuccessCode: "Success",
        TransactionCode: "TX123456",
        IPAddress: "192.168.1.1",
        Days7: 1500,
        Days30: 3000,
        Balance: 4500,
        MTAId: 123,
        PunterCreated: "2022-12-01",
        LoginCount: 15
    }, {
        CreditID: 1,
        CustomerID: 100,
        Amount: 500,
        PunterName: "John Doe",
        CompleteTime: "2023-01-01 12:00",
        SuccessCode: "Success",
        TransactionCode: "TX123456",
        IPAddress: "192.168.1.1",
        Days7: 1500,
        Days30: 3000,
        Balance: 4500,
        MTAId: 123,
        PunterCreated: "2022-12-01",
        LoginCount: 15
    }, {
        CreditID: 1,
        CustomerID: 100,
        Amount: 500,
        PunterName: "John Doe",
        CompleteTime: "2023-01-01 12:00",
        SuccessCode: "Success",
        TransactionCode: "TX123456",
        IPAddress: "192.168.1.1",
        Days7: 1500,
        Days30: 3000,
        Balance: 4500,
        MTAId: 123,
        PunterCreated: "2022-12-01",
        LoginCount: 15
    }
];

type Order = 'asc' | 'desc';

const OnlinePayments : React.FC = () => {
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('CreditID');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [open,
        setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div className={styles.dateRangeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="From">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>
                        </Grid>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="To">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>
                        </Grid>
                        <FormControl
                            fullWidth
                            style={{
                            margin: '1% 1%'
                        }}>
                            <InputLabel htmlFor="outlined-adornment-amount">Transaction Code</InputLabel>
                            <OutlinedInput id="outlined-adornment-amount" label="Terminal UserName"/>
                        </FormControl>
                        <Button
                            style={{
                            margin: '2%',
                            marginTop: '1%'
                        }}
                            variant="contained"
                            color="primary"
                            size="small">
                            Get Data
                        </Button>
                    </Grid>
                </LocalizationProvider>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                        sx={{
                        backgroundColor: 'black',
                        '& .MuiTableCell-head': {
                            color: 'common.white'
                        }
                    }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id
                                    ? order
                                    : false}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id
                                        ? order
                                        : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((row, index) => (
                            <TableRow hover key={index}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    if (column.id === 'CreditID') {
                                        return <TableCell key={column.id} style={{cursor:'pointer', color:'lightblue'}} onClick={handleClickOpen}>
                                            {typeof value === 'number'
                                                ? value.toFixed(2)
                                                : value}
                                        </TableCell>
                                    }
                                    if (column.id === 'PunterName') {
                                        return <TableCell key={column.id} onClick={handleClickOpen}>
                                            <NavLink to = "/AccountWorkingArea" style={{textDecoration:'none', color:'lightblue'}} >
                                            {typeof value === 'number'
                                                ? value.toFixed(2)
                                                : value}
                                            </NavLink>
                                        </TableCell>
                                    }
                                     else {
                                        return (
                                            <TableCell key={column.id}>
                                                {typeof value === 'number'
                                                    ? value.toFixed(2)
                                                    : value}
                                            </TableCell>
                                        );
                                    }

                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dummyData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </TableContainer>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}>
                <DialogTitle style={{backgroundColor:'#1f283e'}}
                    sx={{
                    m: 0,
                    p: 2
                }}
                    id="customized-dialog-title">
                    Details for CreditID 108293
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                }}>
                    <CloseIcon/>
                </IconButton>
                <DialogContent style={{backgroundColor:'#1f283e'}} dividers>
                    <Typography gutterBottom>
                        <DepositDetails/>
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
};

export default OnlinePayments;

import React, { useState } from 'react';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import styles from './MainContainer.module.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor:'#1f283e',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&.MuiTableRow-root': {
    height: '24px', 
  },
}));

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

function createDataTable1(Name: string, Bets: string, Amount: string, Odds: number) {
  return { Name, Bets, Amount, Odds };
}

function createDataTable2(Name: string, TimeStamp: string, Amount: string, Odds: number) {
    return { Name, TimeStamp, Amount, Odds };
}

function createDataTable3(PunterID: number, Name: string, Email: string, Balances: number) {
    return { PunterID, Name, Email, Balances };
}

function createData(Name:string, Today: string, ThisWeek: string, ThisMonth: string) {
    return { Name, Today, ThisWeek, ThisMonth };
}

const rowsTable1 = [
    createDataTable1('Norwich City vs Arsenal', 'Arsenal win', '100', 2.4),
    createDataTable1('Manchester City vs Brighton & Hove Albion', 'Brighton win', '150', 1.8),
    createDataTable1('Leeds United vs Manchester United', 'Manchester United win', '200', 2.0),
    createDataTable1('Liverpool vs Aston Villa', 'Liverpool win', '250', 1.5),
];
const rowsTable2 = [
    createDataTable2('Multi Bet 1', '2024-02-07T10:00:00Z', '100', 2.4),
    createDataTable2('MultiBet 2', '2024-02-07T11:00:00Z', '150', 1.8),
    createDataTable2('MultiBet 3', '2024-02-07T12:00:00Z', '200', 2.0),
    createDataTable2('Multi Bet 4', '2024-02-07T13:00:00Z', '250', 1.5),
];
const rowsTable4 = [
    createDataTable3(7145, 'Punter 1', 'example1@gmail.com', 7720),
    createDataTable3(3403, 'Punter 2', 'example2@gmail.com', 8270),
    createDataTable3(5958, 'Punter 3', 'example3@gmail.com', 5784),
    createDataTable3(2944, 'Punter 4', 'example4@gmail.com', 1914),
];

const rowsTable3 = [
    createData('Total Turnover', 'NAD 891', 'NAD 456,726', 'NAD 4,527,822'),
    createData('Settled Bets', 'NAD 891', 'NAD 45616,7526', 'NAD 414,52547,822'),
    createData('Win payouts on bets', 'NAD 891', 'NAD 456,726', 'NAD -44,527,822'),
    createData('Profit on bets', 'NAD 891', 'NAD 456,726', 'NAD 4,527,822'),
    createData('-   IQBet Turnover', 'NAD 823591', 'NAD 456,726', 'NAD 4,53217,822'),
    createData('-   Settled IQBets', 'NAD 52891', 'NAD 125456,726', 'NAD 4,527,822'),
    createData('-   Win payouts on IQBets', 'NAD 82591', 'NAD 456,726', 'NAD 254,527,822'),
    createData('-   Profit on IQBets', 'NAD 891', 'NAD 456,726', 'NAD 22,527,822'),
    createData('My Lucky 6 Turnover', 'NAD 865391', 'NAD 456,726', 'NAD 34,527,822'),
    createData('My Lucky 6 payout', 'NAD 89871', 'NAD 456,726', 'NAD 354,57427,822'),
    createData('My Lucky 6 Profit', 'NAD 896311', 'NAD 25456,726', 'NAD 4,55827,822'),
];

const ToggleComponent = () => {
    const [alignment, setAlignment] = useState('current'); // 'current' is selected by default
  
    const handleAlignment = (event: any, newAlignment: any) => {
      if (newAlignment !== null) { // Prevent deselecting both options
        setAlignment(newAlignment);
      }
    };
  
    return (
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
        <ToggleButton value="previous">Previous</ToggleButton>
        <ToggleButton value="current">Current</ToggleButton>
      </ToggleButtonGroup>
    );
  };

export default function CustomizedTables() {
  return (
    <>
    {/* <ThemeProvider theme={darkTheme}> */}
    <Typography className={styles.table1Heading} sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div" >
        TOP 10 SINGLE BETS TODAY
    </Typography>

    <TableContainer component={Paper} className={styles.tableWidth}> 
      <Table sx={{ minWidth: 800 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>NAME</StyledTableCell>
            <StyledTableCell align="right">BETS</StyledTableCell>
            <StyledTableCell align="right">AMOUNT ($)</StyledTableCell>
            <StyledTableCell align="right">ODDS</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsTable1.map((row) => (
            <StyledTableRow key={row.Name}>
              <StyledTableCell component="th" scope="row">
                {row.Name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.Bets}</StyledTableCell>
              <StyledTableCell align="right">{row.Amount}</StyledTableCell>
              <StyledTableCell align="right">{row.Odds}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography className={styles.table1Heading} sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div" >
        TOP 10 MULTI BETS TODAY
    </Typography>


    <TableContainer component={Paper} className={styles.tableWidth}> 
      <Table sx={{ minWidth: 800 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>NAME</StyledTableCell>
            <StyledTableCell align="right">TIMESTAMP</StyledTableCell>
            <StyledTableCell align="right">AMOUNT ($)</StyledTableCell>
            <StyledTableCell align="right">ODDS</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsTable2.map((row) => (
            <StyledTableRow key={row.Name}>
              <StyledTableCell component="th" scope="row">
                {row.Name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.TimeStamp}</StyledTableCell>
              <StyledTableCell align="right">{row.Amount}</StyledTableCell>
              <StyledTableCell align="right">{row.Odds}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography className={styles.table1Heading} sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div" >
        STATISTICS
    </Typography>

    <TableContainer component={Paper} className={styles.tableWidth}>
      <Table sx={{ minWidth: 800 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="right">TODAY</StyledTableCell>
            <StyledTableCell align="right">THIS WEEK</StyledTableCell>
            <StyledTableCell align="right">THIS MONTH</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsTable3.map((row) => (
            <StyledTableRow key={row.Name}>
              <StyledTableCell component="th" scope="row">
                {row.Name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.Today}</StyledTableCell>
              <StyledTableCell align="right">{row.ThisWeek}</StyledTableCell>
              <StyledTableCell align="right">{row.ThisMonth}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    <Typography className={styles.table1Heading} sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div" >
        NEGATIVE BALANCES
    </Typography>

    <div className={styles.toggle}>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline /> 
        <ToggleComponent />
        </ThemeProvider>
    </div>

    <div className={styles.lastTable}>
    <TableContainer component={Paper}  className={styles.tableWidth}> 
      <Table sx={{ minWidth: 800 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>PUNTER ID</StyledTableCell>
            <StyledTableCell align="right">NAME</StyledTableCell>
            <StyledTableCell align="right">EMAIL</StyledTableCell>
            <StyledTableCell align="right">BALANCES</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsTable4.map((row) => (
            <StyledTableRow key={row.Name}>
              <StyledTableCell component="th" scope="row">
                {row.PunterID}
              </StyledTableCell>
              <StyledTableCell align="right">{row.Name}</StyledTableCell>
              <StyledTableCell align="right">{row.Email}</StyledTableCell>
              <StyledTableCell align="right">{row.Balances}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
    {/* </ThemeProvider> */}
    </>
    
  );
}

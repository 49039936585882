import React, {useState} from 'react';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import styles from './WeeksTotalTab.module.css';

interface DataRow {
    CountCredits: number;
    CountPunters: number;
    SumAmount: number;
    FirstCompleted: string;
    LastCompleted: string;
    Period: string;
    DepositsFrom: string;
    DepositsTo: string;
    Days: number;
    TransactionCode: string;
    Currency: string;
  }

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns: Column[] = [
    { id: 'CountCredits', label: 'Count Credits' },
    { id: 'CountPunters', label: 'Count Punters' },
    { id: 'SumAmount', label: 'Sum Amount' },
    { id: 'FirstCompleted', label: 'First Completed' },
    { id: 'LastCompleted', label: 'Last Completed' },
    { id: 'Period', label: 'Period' },
    { id: 'DepositsFrom', label: 'Deposits From' },
    { id: 'DepositsTo', label: 'Deposits To' },
    { id: 'Days', label: 'Days' },
    { id: 'TransactionCode', label: 'Transaction Code' },
    { id: 'Currency', label: 'Currency' },
  ];

  const dummyData: DataRow[] = [
    {
      CountCredits: 150,
      CountPunters: 75,
      SumAmount: 10000,
      FirstCompleted: "2022-07-10T12:00:00Z",
      LastCompleted: "2022-07-17T12:00:00Z",
      Period: "2022-W29",
      DepositsFrom: "2022-07-10T00:00:00Z",
      DepositsTo: "2022-07-17T23:59:59Z",
      Days: 7,
      TransactionCode: "TXN123",
      Currency: "USD",
    },
    {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      },
      {
        CountCredits: 150,
        CountPunters: 75,
        SumAmount: 10000,
        FirstCompleted: "2022-07-10T12:00:00Z",
        LastCompleted: "2022-07-17T12:00:00Z",
        Period: "2022-W29",
        DepositsFrom: "2022-07-10T00:00:00Z",
        DepositsTo: "2022-07-17T23:59:59Z",
        Days: 7,
        TransactionCode: "TXN123",
        Currency: "USD",
      }
    
  ];

type Order = 'asc' | 'desc';

const WeeksTotalTab : React.FC = () => {
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('Period');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <div className={styles.dateRangeContainer}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="From">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>
                        </Grid>
                        <Grid item>
                            <DemoContainer components={['DatePicker']}>
                                <DemoItem label="To">
                                    <DatePicker/>
                                </DemoItem>
                            </DemoContainer>
                        </Grid>
                        <Button
                            style={{
                            margin: '2%',
                            marginTop: '4.6%'
                        }}
                            variant="contained"
                            color="primary"
                            size="small">
                            Get Data
                        </Button>
                    </Grid>
                </LocalizationProvider>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead
                    sx={{
                        backgroundColor: 'black',
                        '& .MuiTableCell-head': {
                            color: 'common.white'
                        }
                    }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    sortDirection={orderBy === column.id
                                    ? order
                                    : false}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id
                                        ? order
                                        : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}>
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((row, index) => (
                            <TableRow hover key={index}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                        <TableCell key={column.id}>
                                            {typeof value === 'number'
                                                ? value.toFixed(2)
                                                : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dummyData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}/>
            </TableContainer>
        </div>
    );
};

export default WeeksTotalTab;

import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ThemeProvider} from '@emotion/react';
import Header from '../Header/Header';
import styles from './BetMonitor.module.css';
import {
    Button,
    Checkbox,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    createTheme
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import ViewBetDetails from './ViewBetDetailsTab/ViewBetDetailsTab';
import BetSpeedTab from './BetSpeedTab/BetSpeedTab';
import CashOutTab from './CashOutTab/CashOutTab';
import {NavLink} from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index : number;
    value : number;
}

interface DataRow {
    BetID : number;
    PunterBetID : string;
    BetTime : string;
    Settled : string;
    StakeLocal : number;
    WinLocal : number;
    StakeGBP : number;
    WinGBP : number;
    UserName : string;
    BetSource : string;
    NoOfObjects : number;
    NoOfBets : number;
    BetOdds : number;
    StartOdds : number;
    LastOdds : number;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'BetID',
        label: 'BetID'
    }, {
        id: 'PunterBetID',
        label: 'PunterBetID'
    }, {
        id: 'BetTime',
        label: 'BetTime'
    }, {
        id: 'Settled',
        label: 'Settled'
    }, {
        id: 'StakeLocal',
        label: 'Stake Local'
    }, {
        id: 'WinLocal',
        label: 'Win Local'
    }, {
        id: 'StakeGBP',
        label: 'Stake GBP'
    }, {
        id: 'WinGBP',
        label: 'Win GBP'
    }, {
        id: 'UserName',
        label: 'UserName'
    }, {
        id: 'BetSource',
        label: 'Bet Source'
    }, {
        id: 'NoOfObjects',
        label: 'No Of Objects'
    }, {
        id: 'NoOfBets',
        label: 'No Of Bets'
    }, {
        id: 'BetOdds',
        label: 'Bet Odds'
    }, {
        id: 'StartOdds',
        label: 'Start Odds'
    }, {
        id: 'LastOdds',
        label: 'Last Odds'
    }
];

const dummyData : DataRow[] = [
    {
        BetID: 1,
        PunterBetID: 'P12345',
        BetTime: '2024-02-25T08:00:00Z',
        Settled: '2024-02-26T15:30:00Z',
        StakeLocal: 100,
        WinLocal: 200,
        StakeGBP: 75,
        WinGBP: 150,
        UserName: 'UserOne',
        BetSource: 'Online',
        NoOfObjects: 2,
        NoOfBets: 1,
        BetOdds: 2.0,
        StartOdds: 1.8,
        LastOdds: 2.1
    }, {
        BetID: 2,
        PunterBetID: 'P67890',
        BetTime: '2024-02-24T10:30:00Z',
        Settled: '2024-02-25T16:45:00Z',
        StakeLocal: 50,
        WinLocal: 0,
        StakeGBP: 40,
        WinGBP: 0,
        UserName: 'UserTwo',
        BetSource: 'Mobile',
        NoOfObjects: 1,
        NoOfBets: 2,
        BetOdds: 1.5,
        StartOdds: 1.4,
        LastOdds: 1.6
    }
];

function CustomTabPanel(props : TabPanelProps) {
    const {
        children,
        value,
        index,
        ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{
                    p: 3
                }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index : number) {
    return {id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`};
}

type Order = 'asc' | 'desc';

export default function BetMonitor() {
    const [value,
        setValue] = React.useState(0);
    const [selectedColumns,
        setSelectedColumns] = React.useState(columns.map(column => column.id));

    const [order,
        setOrder] = React.useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = React.useState < keyof DataRow > ('BetID');
    const [page,
        setPage] = React.useState(0);
    const [rowsPerPage,
        setRowsPerPage] = React.useState(5);

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (event : React.SyntheticEvent, newValue : number) => {
        setValue(newValue);
    };

    const handleColumnChange = (event : any) => {
        const {target: {
                value
            }} = event;
        setSelectedColumns(typeof value === 'string'
            ? value.split(',')
            : value);
    };

    const downloadCSV = () => {
        const csv = Papa.unparse(dummyData);
        const csvBlob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        saveAs(csvBlob, 'table_data.csv');
    };

    const downloadExcel = () => {
        const ws = XLSX
            .utils
            .json_to_sheet(dummyData);
        const wb = XLSX
            .utils
            .book_new();
        XLSX
            .utils
            .book_append_sheet(wb, ws, "TableData");
        XLSX.writeFile(wb, 'table_data.xlsx');
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
            <ThemeProvider theme={darkTheme}>
                <div className={styles.mainContainer}>
                    <div className={styles.subHeader}>
                        <h4>Bet Monitor</h4>
                    </div>
                    <Box sx={{
                        width: '100%'
                    }}>
                        <Box
                            sx={{
                            borderBottom: 1,
                            borderColor: 'divider'
                        }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Highest Payout Bets" {...a11yProps(0)}/>
                                <Tab label="View Bet Details" {...a11yProps(1)}/>
                                <Tab label="Bet Speed" {...a11yProps(2)}/>
                                <Tab label="Cash-Out Stats" {...a11yProps(2)}/>
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className={styles.dateRangeContainer}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <DemoContainer components={['DatePicker']}>
                                                <DemoItem label="From">
                                                    <DatePicker/>
                                                </DemoItem>
                                            </DemoContainer>
                                        </Grid>
                                        <Grid item>
                                            <DemoContainer components={['DatePicker']}>
                                                <DemoItem label="To">
                                                    <DatePicker/>
                                                </DemoItem>
                                            </DemoContainer>

                                        </Grid>
                                        <TextField
                                            style={{
                                            marginTop: '3.8%',
                                            marginLeft: '1%'
                                        }}
                                            id="outlined-basic"
                                            label="Max Rows"
                                            variant="outlined"/>
                                        <TextField
                                            style={{
                                            marginTop: '3.8%',
                                            marginLeft: '1%'
                                        }}
                                            id="outlined-basic"
                                            label="Limit Win GBP"
                                            variant="outlined"/>
                                        <Button
                                            style={{
                                            margin: '2%',
                                            marginTop: '4.6%'
                                        }}
                                            variant="contained"
                                            color="primary"
                                            size="small">
                                            Get Data
                                        </Button>
                                    </Grid>
                                </LocalizationProvider>
                            </div>
                            <div
                                style={{
                                margin: '2% 2%',
                                display: 'flex'
                            }}>
                                <FormControl
                                    style={{
                                    width: '25%'
                                }}>
                                    <Select
                                        labelId="column-select-label"
                                        multiple
                                        value={selectedColumns}
                                        onChange={handleColumnChange}
                                        renderValue={(selected) => "Column Visibility"}>
                                        {columns.map((column) => (
                                            <MenuItem key={column.id} value={column.id}>
                                                <Checkbox checked={selectedColumns.indexOf(column.id) > -1}/> {column.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <Button
                                    variant="contained"
                                    size="small"
                                    style={{
                                    margin: '0% 2%'
                                }}
                                    color="primary"
                                    onClick={downloadCSV}>Download CSV</Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    onClick={downloadExcel}>Download Excel</Button>

                            </div>
                            <div >
                                <TableContainer
                                    component={Paper}
                                    style={{
                                    border: '1px solid rgba(81, 81, 81, 1)'
                                }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead >
                                            <TableRow
                                                style={{
                                                backgroundColor: 'green !important'
                                            }}>
                                                {columns
                                                    .filter(column => selectedColumns.includes(column.id))
                                                    .map((column) => (
                                                        <TableCell
                                                            sx={{
                                                            backgroundColor: '#1f283e'
                                                        }}
                                                            key={column.id}
                                                            sortDirection={orderBy === column.id
                                                            ? order
                                                            : false}>
                                                            <TableSortLabel
                                                                style={{
                                                                backgroundColor: 'green !important'
                                                            }}
                                                                active={orderBy === column.id}
                                                                direction={orderBy === column.id
                                                                ? order
                                                                : 'asc'}
                                                                onClick={() => handleRequestSort(column.id)}>
                                                                {column.label}
                                                            </TableSortLabel>
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedData().map((row, index) => (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                    {columns
                                                        .filter(column => selectedColumns.includes(column.id))
                                                        .map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    sx={{
                                                                    backgroundColor: '#1f283e',
                                                                    color: 'white'
                                                                }}
                                                                    key={column.id}>
                                                                    {column.id === 'BetID'
                                                                        ? (
                                                                            <span
                                                                                style={{
                                                                                color: 'lightblue',
                                                                                marginLeft: '2%',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                                onClick={() => setValue(1)}>
                                                                                {value}
                                                                            </span>
                                                                        )
                                                                        : column.id === 'UserName'
                                                                            ? (
                                                                                <NavLink
                                                                                    to="/AccountWorkingArea"
                                                                                    className={({isActive}) => isActive
                                                                                    ? styles.activeLink
                                                                                    : ''}>
                                                                                    <span
                                                                                        style={{
                                                                                        color: 'lightblue',
                                                                                        marginLeft: '2%',
                                                                                        cursor: 'pointer'
                                                                                    }}>
                                                                                        {value}
                                                                                    </span>
                                                                                </NavLink>

                                                                            )
                                                                            : (value)}
                                                                </TableCell>
                                                            );
                                                        })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={dummyData.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}/>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <ViewBetDetails/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <BetSpeedTab/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={3}>
                            <CashOutTab setValue={setValue}/>
                        </CustomTabPanel>
                    </Box>
                </div>
            </ThemeProvider>
        </div>
    );
}

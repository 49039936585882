import React from 'react';
import {
    Box,
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    TextField as MuiTextField, 
    styled
} from '@mui/material';
import Header from '../Header/Header';
import styles from './AccountWorkingArea.module.css'
import { NavLink } from 'react-router-dom';

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});



const CustomTextField = styled(MuiTextField)({
    '& .MuiInputBase-input': {
      height: 40, // Adjust height
      padding: '0 14px', // Adjust padding
      fontSize: '0.875rem', // Adjust font size
    },
    '& .MuiInputLabel-root': {
      lineHeight: '1.4375em', // Adjust label line height
      fontSize: '0.875rem', // Adjust label font size
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 14px) scale(1)', // Adjust this based on your input's height and padding
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  });

const AccountWorkingArea : React.FC = () => {
    return (
        <div style={{
            width: '84vw'
        }}>
            <Header/>
        <ThemeProvider theme={theme}>
        <div className={styles.mainContainer}>
            <Container maxWidth="lg">
                <Typography style={{padding:'2% 0%'}} variant="h5" gutterBottom>
                    Account Working Area
                </Typography>

                    <Grid>
                        <Box padding={2}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Save</Button>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Send Email</Button>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">View IP</Button>
                        </Box>
                    </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                            <Typography variant="h6">Personal Information</Typography>
                            <CustomTextField label="Title" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="First Name" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Last Name" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Nick Name" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField
                                label="Email"
                                type="email"
                                variant="outlined"
                                fullWidth
                                margin="normal"/>
                            <CustomTextField
                                label="Birthdate"
                                type="date"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                shrink: true
                            }}/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                            <Typography variant="h6">Account Information</Typography>
                            <CustomTextField label="MaxBet" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="MaxDeposit" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="MaxBetPercent" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField
                                label="Creditcard Fails"
                                variant="outlined"
                                fullWidth
                                margin="normal"/>
                            <FormControlLabel control={< Checkbox />} label="Accept Info"/>
                            <FormControlLabel control={< Checkbox />} label="Allow Transfer"/>
                            <FormControlLabel control={< Checkbox />} label="Suspended"/>
                            <CustomTextField
                                label="Suspended Description"
                                variant="outlined"
                                fullWidth
                                margin="normal"/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                            <Typography variant="h6">Location</Typography>
                            <CustomTextField label="Street 1" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Street 2" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Zip Code" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="City" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="State" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Cell Phone" variant="outlined" fullWidth margin="normal"/>
                            <CustomTextField label="Country" variant="outlined" fullWidth margin="normal"/>
                            <FormControlLabel control={< Checkbox />} label="Deposit/Withdrawal Agreement"/>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                            <Typography variant="h6" gutterBottom>Additional Information</Typography>
                            <Typography variant="body1" gutterBottom>Balance: -81.00 XAU</Typography>
                            <Typography variant="body1" gutterBottom>Created: 18/04/2020 01:37:49</Typography>
                            <Typography variant="body1" gutterBottom>Last Changed:
                            </Typography>
                            <Typography variant="body1" gutterBottom>Login Count: 0</Typography>
                            <Typography variant="body1" gutterBottom>Last Login Date:
                            </Typography>
                            <Typography variant="body1" gutterBottom>Balance Virtual: 1,000.00</Typography>
                            <Typography variant="body1" gutterBottom>Member: True</Typography>
                            <Typography variant="body1" gutterBottom>Failed Login: 0</Typography>
                            <Typography variant="body1" gutterBottom>Active: True</Typography>
                            <Typography variant="body1" gutterBottom>Updated Admin: Eugeny</Typography>
                            <Typography variant="body1" gutterBottom>Referral Code: AgPID:9124;MTA178</Typography>
                            <TextField
                                label="Created From"
                                select
                                variant="outlined"
                                fullWidth
                                margin='normal'
                                >
                                {/* Options for the selector */}
                            </TextField>
                        </Box>
                    </Grid>

                    <Grid item >
                        <Box padding={2} boxShadow={3} borderRadius={2} sx={{ border: '1px solid grey' }}>
                            <Typography variant="h6">Options</Typography>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Betting Section</Button>
                            <NavLink to="/UserTransaction" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">View All Transactions</Button>
                            </NavLink>  
                            <Button style={{margin:'1%'}} variant="contained" color="primary">View Changed Details</Button>
                            <NavLink to="/BetStatistics" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">View Bet Statistics</Button>
                            </NavLink>
                            <NavLink to="/MoneyDeposit" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Deposit Money</Button>
                            </NavLink>
                            <NavLink to="/WithdrawMoney" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Withdraw Money</Button>
                            </NavLink>
                            
                            {/* <Button style={{margin:'1%'}} variant="contained" color="primary">Deposit Virtual Money</Button> */}
                            <NavLink to="/ChangeShop" className={({ isActive }) => isActive ? styles.activeLink : ''}>
                            <Button style={{margin:'1%'}} variant="contained" color="primary">Change Shop</Button>
                            </NavLink>
                            
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            </div>
        </ThemeProvider>
        </div>
    );
};

export default AccountWorkingArea;

// export default AccountWorkingArea
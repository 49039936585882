import React from 'react';
import {
    Box,
    Container,

    Typography,
    createTheme,
    ThemeProvider,
    Grid,
    Divider
} from '@mui/material';
import styles from './DepositDetails.module.css'

const theme = createTheme({
    palette: {
        mode: 'dark'
    }
});

const DepositDetails = () => {
    return (
        <div>
            <ThemeProvider theme={theme}>
                <div className={styles.mainContainer}>
                    <Container maxWidth="lg">
                        <Grid >
                            <Box
                                padding={2}
                                boxShadow={3}
                                borderRadius={2}
                                sx={{
                                border: '1px solid grey'
                            }}>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>
                                        CreditID:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>108293</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>PunterID:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>14663</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>CustomerID:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>XAU-873089</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Amount:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>XAU 1.00</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Punter Name:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '35%'
                                    }}
                                        variant="body1"
                                        gutterBottom>John Doe, UserName:newusername7251</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Request Time:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>2024-02-09 10:35</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Complete Time:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>2023-03-09 11:50</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Success Code:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>Y</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Author Code:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>108293</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Transaction Code:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>MobileAgentApp</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>IPAddress:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>178.73.195.138</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>7Days:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>0(-)</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>30Days:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>(2) XAU 2.00</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Balance:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>XAU 5.50</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>MTAId:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom></Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Punter Created:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>
                                        2023-04-26 13:29</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Login Count:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>
                                        2</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Bet Count:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>0</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Game Count:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>0</Typography>
                                </div>
                                <Divider/>
                                <div
                                    style={{
                                    display: 'flex',
                                    alignItems: 'space-between'
                                }}>
                                    <Typography
                                        style={{
                                        marginLeft: '6%',
                                        width: '200px'
                                    }}
                                        variant="body1"
                                        gutterBottom>Suspended:</Typography>
                                    <Typography
                                        style={{
                                        marginLeft: '12%'
                                    }}
                                        variant="body1"
                                        gutterBottom>False</Typography>
                                </div>
                            </Box>
                        </Grid>
                    </Container>
                </div>
            </ThemeProvider>
        </div>
    )
}

export default DepositDetails
import React from 'react'
import Header from '../Header/Header';
import MainContainer from '../MainContainer/MainContainer';

const Home = () => {
  return (
    <>
    <Header/>
    <MainContainer />
    </>
  )
}

export default Home
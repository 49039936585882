import React, {useState} from 'react';
import {
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TableSortLabel,
    ThemeProvider,
    createTheme,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    Theme,
    MenuItem,
    OutlinedInput
} from '@mui/material';
import { LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import styles from './ShopDetails.module.css';
import Header from '../Header/Header';

interface DataRow {
    ShopID : string;
    BookID : string;
    ShopUsername : string;
    ShopName : string;
    Address1 : string;
    Address2 : string;
    Address3 : string;
    State : string;
    PostalCode : string;
    Email : string;
    Active : boolean;
}

type Column = {
    id: keyof DataRow;
    label: string;
};

const columns : Column[] = [
    {
        id: 'ShopID',
        label: 'Shop ID'
    }, {
        id: 'BookID',
        label: 'Book ID'
    }, {
        id: 'ShopUsername',
        label: 'Shop Username'
    }, {
        id: 'ShopName',
        label: 'Shop Name'
    }, {
        id: 'Address1',
        label: 'Address 1'
    }, {
        id: 'Address2',
        label: 'Address 2'
    }, {
        id: 'Address3',
        label: 'Address 3'
    }, {
        id: 'State',
        label: 'State'
    }, {
        id: 'PostalCode',
        label: 'Postal Code'
    }, {
        id: 'Email',
        label: 'Email'
    }, {
        id: 'Active',
        label: 'Active'
    }
];

const dummyData : DataRow[] = [
    {
        ShopID: "1",
        BookID: "101",
        ShopUsername: "shopUser1",
        ShopName: "Shop One",
        Address1: "123 Main St",
        Address2: "Suite A",
        Address3: "",
        State: "StateOne",
        PostalCode: "12345",
        Email: "email1@example.com",
        Active: true
    }, {
        ShopID: "2",
        BookID: "102",
        ShopUsername: "shopUser2",
        ShopName: "Shop Two",
        Address1: "456 Market St",
        Address2: "Suite B",
        Address3: "",
        State: "StateTwo",
        PostalCode: "67890",
        Email: "email2@example.com",
        Active: false
    },
];

type Order = 'asc' | 'desc';

const names = ['Shop Ghana', 'Shop Namibia'];

function getStyles(name : string, personName : string[], theme : Theme) {
    return {
        fontWeight: personName.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium
    };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const ShopDetails : React.FC = () => {
    const [order,
        setOrder] = useState < Order > ('asc');
    const [orderBy,
        setOrderBy] = useState < keyof DataRow > ('ShopID');
    const [page,
        setPage] = useState(0);
    const [rowsPerPage,
        setRowsPerPage] = useState(5);

    const [storeName,
        setStoreName] = React.useState < string[] > ([]);

    const handleChange = (event : SelectChangeEvent < typeof storeName >) => {
        const {target: {
                value
            }} = event;
        setStoreName(typeof value === 'string'
            ? value.split(',')
            : value,);
    };

    const handleRequestSort = (property : keyof DataRow) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc
            ? 'desc'
            : 'asc');
        setOrderBy(property);
    };

    const sortedData = () : DataRow[] => {
        return dummyData.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return order === 'asc'
                    ? -1
                    : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return order === 'asc'
                    ? 1
                    : -1;
            }
            return 0;
        }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    };

    const handleChangePage = (event : unknown, newPage : number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event : React.ChangeEvent < HTMLInputElement >) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const darkTheme = createTheme({
        palette: {
            mode: 'dark'
        }
    });

    return (

        <div>
            <div style={{
                width: '84vw'
            }}>
                <Header/>
                <ThemeProvider theme={darkTheme}>
                    <div className={styles.mainContainer}>
                        <div className={styles.subHeader}>
                            <h4>Shop Details</h4>
                        </div>
                        <div className={styles.dateRangeContainer}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormControl
                                            sx={{
                                            m: 1,
                                            width: 300
                                        }}>
                                            <InputLabel id="demo-multiple-name-label">Shop Name</InputLabel>
                                            <Select
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                multiple
                                                value={storeName}
                                                onChange={handleChange}
                                                input={< OutlinedInput label = "Name" />}
                                                MenuProps={MenuProps}>
                                                {names.map((name) => (
                                                    <MenuItem key={name} value={name} style={getStyles(name, storeName, darkTheme)}>
                                                        {name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Button
                                        style={{
                                        margin: '2%',
                                        marginTop: '2.6%'
                                    }}
                                        variant="contained"
                                        color="primary"
                                        size="small">
                                        Get Data
                                    </Button>
                                </Grid>
                            </LocalizationProvider>
                        </div>
                        <TableContainer component={Paper}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                sortDirection={orderBy === column.id
                                                ? order
                                                : false}>
                                                <TableSortLabel
                                                    active={orderBy === column.id}
                                                    direction={orderBy === column.id
                                                    ? order
                                                    : 'asc'}
                                                    onClick={() => handleRequestSort(column.id)}>
                                                    {column.label}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedData().map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id}>
                                                        {typeof value === 'boolean'
                                                            ? (value
                                                                ? 'Yes'
                                                                : 'No')
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={dummyData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}/>
                        </TableContainer>
                    </div>

                </ThemeProvider>
            </div>
        </div>
    );
};

export default ShopDetails;
